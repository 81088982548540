<template>
    <div
        class="pa-3"
        :class="{
            'header-editor-wrapper': header,
            'footer-editor-wrapper': footer,
        }"
    >
        <div class="d-flex flex-wrap align-center">
            <template v-for="(item, idx) in mainMenu">
                <uv-button
                    :key="`item_${idx}`"
                    v-if="!item.showCondition || item.showCondition()"
                    icon
                    :height="item.height || 32"
                    :width="item.width || 32"
                    @click.native.stop="item.command()"
                    :title="item.title"
                    :btn-classes="{
                        'mx-1': true,
                        'is-active': item.active && item.active(),
                    }"
                >
                    <v-icon size="20" :color="item.color || 'black'">{{
                        item.icon
                    }}</v-icon>
                </uv-button>

                <v-divider
                    class="mx-2"
                    vertical
                    v-if="item.divider && item.divider()"
                    :key="`divider_${idx}`"
                ></v-divider>
            </template>
        </div>
        <div class="d-flex flex-wrap align-center pb-4">
            <div>
                <v-container class="pa-0" :style="{ width: '300px' }">
                    <v-row no-gutters>
                        <v-col cols="9">
                            <v-select
                                class="pr-1"
                                v-model="fontSelectedItem"
                                :items="fontSelectItems"
                                dense
                                label="Font"
                                solo
                                hide-details
                                @change="onFontChange"
                            >
                                <template #item="{item}">
                                    <span
                                        :style="{
                                            fontFamily: item.value,
                                        }"
                                        >{{ item.text }}</span
                                    >
                                </template>
                            </v-select>
                        </v-col>
                        <v-col cols="3">
                            <v-select
                                v-model="fontSize"
                                :items="fontSizeSelectItems"
                                dense
                                label="Size"
                                solo
                                hide-details
                                @change="onFontSizeChange"
                            >
                            </v-select>
                        </v-col>
                    </v-row>
                </v-container>
            </div>
            <v-menu :close-on-content-click="false" :value="displayColorPickerMenu">
                <template #activator="{ attrs, on}">
                    <uv-button 
                        v-on="on" 
                        v-bind="attrs" 
                        icon 
                        title="Color"
                        @click="displayColorPickerMenu = true">
                        <v-icon size="20" color="black">mdi-palette</v-icon>
                    </uv-button>
                </template>
                <v-color-picker
                    dot-size="25"
                    show-swatches
                    :swatches="colorSwatches"
                    swatches-max-height="200"
                    v-model="textColor"
                    @dblclick.native="handleApplyFontColor(textColor),displayColorPickerMenu = false"
                    @keyup.native.enter="handleApplyFontColor(textColor),displayColorPickerMenu = false">
                ></v-color-picker>
                <div class="d-flex justify-space-between py-1 align-center" style="background-color: white">
                    <div class="justify-start px-1">
                        <uv-button
                            class="px-1"
                            elevation="0"
                            small
                            :color="textColor"
                            @click.native.stop="handleApplyFontColor(textColor),displayColorPickerMenu = false"
                            title="Detected color">
                                <v-icon
                                    size="20"
                                    class="outlined rounded-pill"
                                    style="background-color: white; outline: 1px solid black"
                                    color="black">
                                    mdi-border-color
                                </v-icon>
                        </uv-button>
                    </div>
                    <div class="justify-end">
                        <uv-button
                            small
                            title="Close Menu"
                            color="grey darken-1" 
                            plain 
                            @click.native.stop="
                                displayColorPickerMenu = false,
                                editor.commands.focus()">
                            Cancel
                        </uv-button>
                        <uv-button
                            small
                            title="Apply Font Color"
                            color="green darken-1" 
                            plain 
                            @click.native.stop="handleApplyFontColor(textColor);displayColorPickerMenu = false">
                            Apply
                        </uv-button>
                    </div>
                </div>
            </v-menu>
            <uv-button
                @click="
                    editor
                        .chain()
                        .focus()
                        .unsetMark('textStyle')
                        .run()
                "
                icon
                title="Clear format"
            >
                <v-icon size="20" color="black">mdi-format-clear</v-icon>
            </uv-button>
            <v-tooltip top>
                <template #activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                        <v-file-input
                            class="image-file-input"
                            hide-details
                            hide-input
                            dense
                            @change="onImageLoad"
                            accept="image/*"
                        >
                        </v-file-input>
                    </div>
                </template>
                <span>Insert image</span>
            </v-tooltip>
        </div>
        <div
            :class="{
                'header-editor-decorator': header,
                'footer-editor-decorator': footer,
                'd-flex flex-column': footer,
                landscape,
            }"
        >
            <div v-if="footer">
                <p :style="{color: displaySampleText ? 'black' : 'transparent'}">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam in risus eu neque tristique euismod. Proin a enim
                    nunc. Donec euismod elementum tellus eget porta. Donec sed
                    metus velit. Cras tempus lectus eget elit facilisis, sed
                    rutrum dui porttitor. Donec placerat ullamcorper mattis.
                    Mauris volutpat dui vitae ex tempor venenatis. Donec
                    convallis mauris eu aliquam pulvinar. Ut quis urna ut nibh
                    auctor eleifend.
                </p>
                <p :style="{color: displaySampleText ? 'black' : 'transparent'}">
                    Quisque ac nisi at est porttitor mollis. Proin id
                    consectetur leo, ut hendrerit libero. Ut placerat nisi
                    risus, id placerat lorem lobortis consectetur. Donec non
                    enim semper, viverra leo at, porttitor turpis. Donec odio
                    erat, aliquam non vulputate in, aliquam eget est. Aliquam
                    ipsum velit, dapibus et lobortis id, porttitor a nibh.
                    Suspendisse finibus enim elit, varius ultricies purus auctor
                    non. Suspendisse elementum ex erat, eu ultricies lorem
                    egestas nec. Phasellus non blandit ipsum.
                </p>
            </div>
            <editor-content
                :editor="editor"
                :class="{
                    'push-footer': footer,
                }"
            ></editor-content>
            <div v-if="header">
                <p :style="{color: displaySampleText ? 'black' : 'transparent'}">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Etiam in risus eu neque tristique euismod. Proin a enim
                    nunc. Donec euismod elementum tellus eget porta. Donec sed
                    metus velit. Cras tempus lectus eget elit facilisis, sed
                    rutrum dui porttitor. Donec placerat ullamcorper mattis.
                    Mauris volutpat dui vitae ex tempor venenatis. Donec
                    convallis mauris eu aliquam pulvinar. Ut quis urna ut nibh
                    auctor eleifend.
                </p>
                <p :style="{color: displaySampleText ? 'black' : 'transparent'}">
                    Quisque ac nisi at est porttitor mollis. Proin id
                    consectetur leo, ut hendrerit libero. Ut placerat nisi
                    risus, id placerat lorem lobortis consectetur. Donec non
                    enim semper, viverra leo at, porttitor turpis. Donec odio
                    erat, aliquam non vulputate in, aliquam eget est. Aliquam
                    ipsum velit, dapibus et lobortis id, porttitor a nibh.
                    Suspendisse finibus enim elit, varius ultricies purus auctor
                    non. Suspendisse elementum ex erat, eu ultricies lorem
                    egestas nec. Phasellus non blandit ipsum.
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import headerFooterEditorMixins from "./headerFooterEditorMixins";
import Focus from "@tiptap/extension-focus";
import TextAlign from "@tiptap/extension-text-align";
import FontFamily from "@tiptap/extension-font-family";
import Color from "@tiptap/extension-color";
import Image from "@tiptap/extension-image";
import Dropcursor from "@tiptap/extension-dropcursor";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import TipTapTextStyle from "@tiptap/extension-text-style";
import {
    CustomUnderline
} from "@/editor/marks"
import {
    TextStyle,
    GeneralStyle
} from "@/editor/extensions"
import {
    CustomParagraph,
    CustomHeading,
    CustomImage
} from "@/editor/nodes"
import editorConfig from "@/views/Reports/editorConfig";

export default {
    name: "HeaderFooterEditor",
    props: ["header", "footer", "isVisible"],
    components: {
        EditorContent,
    },
    mixins: [headerFooterEditorMixins],
    created() {
        this.editor.on("update", ({ editor }) => {
            this.$emit("input", {
                editorObject: editor.getJSON(),
                height: editor.view.dom.offsetHeight,
            });

            if (this.displaySampleText) this.displaySampleText = false;
        });
    },
    data() {
        return {
            editor: new Editor({
                extensions: [
                    CustomUnderline,
                    StarterKit.configure({
                        paragraph: false,
                        document: false,
                        heading: false,
                        bulletList: {
                            keepAttributes: true,
                            keepMarks: true
                        },
                        orderedList:{
                            keepAttributes: true,
                            keepMarks: true
                        }
                    }),
                    CustomParagraph,
                    CustomHeading.configure({
                        levels: [1, 2, 3],
                    }),
                    Focus.configure({
                        className: "has-focus",
                        mode: "all",
                    }),
                    TextAlign.configure({
                        types: editorConfig?.TextAlign.types
                    }),
                    GeneralStyle.configure({
                        types: editorConfig?.GeneralStyle.types
                    }),
                    TipTapTextStyle,
                    TextStyle.configure({
                        types: editorConfig?.TextStyle.types
                    }),
                    FontFamily.configure({
                        types: editorConfig?.FontFamily.types
                    }),
                    Color.configure({
                        types: editorConfig?.Color.types
                    }),
                    Image,
                    CustomImage.configure({
                        inline: true,
                    }),
                    Dropcursor,
                    HorizontalRule,
                ],
                editorProps: {
                    attributes: {
                        class: "header-footer-editor",
                    },
                },
                autofocus: true,
            }),

            landscape: false,
            displaySampleText: true,
        };
    },
    methods: {
        handleApplyFontColor(color) {
            this.editor.chain().setColor(color).run();
        },
    },
    beforeDestroy() {
        this.editor.destroy();
    },
    watch: {
        isVisible: function(newVal, oldVal){
            if (!oldVal && newVal){
                this.displaySampleText = true;
            }
        }
    }
};
</script>

<style lang="scss">
.header-editor-wrapper {
    min-height: 100%;
    background-color: lightgray;

    p {
        margin-bottom: 0;
        line-height: 1.3;
    }
}
.footer-editor-wrapper {
    min-height: 100%;
    background-color: lightgray;

    p {
        margin-bottom: 0;
        line-height: 1.3;
    }
    padding-bottom: 50px;
}

.header-editor-decorator {
    width: 220mm;
    padding: 1cm 1.8cm 0 1.8cm;
    &.landscape {
        width: 297mm;
    }
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}

.footer-editor-decorator {
    height: 100%;
    width: 220mm;
    &.landscape {
        width: 297mm;
    }
    padding: 0 1.8cm 1cm 1.8cm;
    background-color: white;
    margin-left: auto;
    margin-right: auto;
}

.header-footer-editor {
    &.ProseMirror-focused {
        outline-style: none;
        background-color: lightgray;
    }
    &:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }
}

.push-footer {
    margin-top: auto;
}
</style>
