import Color from "@tiptap/extension-color"

const CustomColor = Color.extend({
	addGlobalAttributes() {
		return [
			...this.parent?.(),
			{
				types: this.options.types,
				attributes: {
					backgroundColor: {
						parseHTML(el) {
							return el.style.backgroundColor?.replace(
								/['"]+/g,
								""
							);
						},
						renderHTML(attributes) {
							if (!attributes.backgroundColor) {
								return {};
							}
							return {
								style: `background-color: ${attributes.backgroundColor}`,
							};
						},
					},
				},
			},
		];
	},
	addCommands() {
		return {
			...this.parent?.(),
			setBackgroundColor: (backgroundColor) => ({chain}) => {
				return chain()
					.setMark("textStyle", {backgroundColor})
					.run()
			},
			unsetBackgroundColor: () => ({chain}) => {
				return chain()
					.setMark("textStyle", {backgroundColor: null})
					.removeEmptyTextStyle()
					.run()
			},
		};
	},
});

export default CustomColor
