<template>
    <node-view-wrapper class="row-view" as="tr" :data-xbrl-id="node.attrs.xbrlId">
        <node-view-content class="node-content"/>
    </node-view-wrapper>
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from "@tiptap/vue-2";
import { mapMutations, mapState } from "vuex";

export default {
    name: "TableRowView",
    components: {
        NodeViewWrapper,
        NodeViewContent,
    },
    props: {
        node: {
            type: Object,
            // required: true,
        },
        selected: {
            type: Boolean,
        },
        deleteNode: {
            type: Function,
        },
        editor: {
            type: Object,
        },
        getPos: {
            type: Function,
        },
    },
    computed: {
        ...mapState("xbrlData", {
            selection: state => state.editorSelection,
            reportInfo: state => state.reportInfo,
        }),
        isSimpleReport() {
            return !this.reportInfo?.xbrl;
        }
    },
    methods: {
        ...mapMutations("xbrlData", [
            "setEditorSelection",
        ]),
    },
    beforeDestroy() {
        // this.deleteNode();
    },
};
</script>

<style lang="scss">

.row-view {
    position: relative;
    .menu-button {
        position: absolute;
        right: 0;
        text-align: center;
        visibility: hidden;
        opacity: 0;
        transition: 1s;
    }
    &:hover{
        .menu-button {
            position: absolute;
            top: -0.5em;
            right: -2.3em;
            visibility: visible;
            opacity: 1;
            transition: 0.1s;
        }
    }
    .node-content {
        display: contents;
    }
    &[data-xbrl-id]:hover {
        z-index: 3;
        outline: 3px dashed blue;
    }
    &[data-xbrl-id].has-focus{
        z-index: 3;
        outline: 3px solid blue;
    }
}

</style>
