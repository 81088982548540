const { mapState } = require("vuex");
const {
    getCssStyleSheetsBySelector,
    getCssStyleSheetsString,
    colourNameToHex
} = require("./utils");
const { requestStatusEnum } = require("@/plugins/utils.js");
module.exports = {
    editorMenu: {
        data() {
            return {
                editorMenu: {
                    mainMenu: [
                        {
                            command: () => {
                                if (this.websocket.edit_status)
                                    this.editor
                                        .chain()
                                        .focus()
                                        .undo()
                                        .run()
                            },
                            title: "Undo",
                            active: () => false,
                            icon: "mdi-undo-variant",
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status)
                                    this.editor
                                        .chain()
                                        .focus()
                                        .redo()
                                        .run()
                            },
                            title: "Redo",
                            active: () => false,
                            icon: "mdi-redo-variant",
                            divider: () => true,
                        },
                        {
                            command: async (thisItem) => {
                                if (this.websocket.edit_status) {
                                    thisItem.busy = true;
                                    this.saveContent(false, true).finally(() => thisItem.busy = false);
                                }
                            },
                            active: () => false,
                            busy: false,
                            title: "Save",
                            icon: "mdi-content-save",
                            divider: () => true,
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .toggleBold()
                                        .run();
                                }
                            },
                            active: () => this.editor.isActive("bold"),
                            title: "Bold",
                            icon: "mdi-format-bold",
                            color: "black",
                            height: "32",
                            width: "32",
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .toggleItalic()
                                        .run();
                                }
                            },
                            active: () => this.editor.isActive("italic"),
                            title: "Italic",
                            icon: "mdi-format-italic",
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .toggleStrike()
                                        .run();
                                }
                            },
                            active: () => this.editor.isActive("strike"),
                            title: "Strike",
                            icon: "mdi-format-strikethrough-variant",
                            color: "black",
                            height: "32",
                            width: "32",
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .toggleUnderline()
                                        .run();
                                }
                            },
                            active: () => this.editor.isActive("underline"),
                            title: "Underline",
                            icon: "mdi-format-underline",
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .toggleUnderline()
                                        .updateAttributes("underline", {
                                            double: true,
                                        })
                                        .run();
                                }
                            },
                            active: () =>
                                this.editor.isActive("underline", {
                                    double: true,
                                }),
                            title: "Double underline",
                            icon: "mdi-drag-horizontal-variant",
                            divider: () => true,
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .setTextAlign("left")
                                        .run();
                                }
                            },
                            active: () => 
                                this.editor.isActive({ textAlign: "left" }) &&
                                !(
                                    this.editor.isActive({textAlign: "justify"}) ||
                                    this.editor.isActive({textAlign: "center"}) ||
                                    this.editor.isActive({textAlign: "right"})
                                ),
                            title: "Align left",
                            icon: "mdi-format-align-left",
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .setTextAlign("center")
                                        .run();
                                }
                            },
                            active: () =>
                                this.editor.isActive({ textAlign: "center" }),
                            title: "Align center",
                            icon: "mdi-format-align-center",
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .setTextAlign("right")
                                        .run();
                                }
                            },
                            active: () =>
                                this.editor.isActive({ textAlign: "right" }),
                            title: "Align right",
                            icon: "mdi-format-align-right",
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .setTextAlign("justify")
                                        .run();
                                }
                            },
                            active: () =>
                                this.editor.isActive({ textAlign: "justify" }),
                            title: "Justify",
                            icon: "mdi-format-align-justify",
                            divider: () => true,
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .wrapPageNode()
                                        .run();
                                }
                            },
                            title: "Page",
                            icon: "mdi-format-page-break",
                            active: () => this.editor.isActive("pageNode"),
                            showCondition: () =>
                                !this.editor.isActive("pageNode"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    let position;
                                    let result = this.editor
                                        .chain()
                                        .addPage((pos) => {
                                            position = pos;
                                        })
                                        .run();
                                    if (result) this.editor.commands.focus(position + 2);
                                }
                            },
                            title: "New page",
                            icon: "mdi-text-box-plus-outline",
                            showCondition: () =>
                                this.editor.isActive("pageNode"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    let position;
                                    let result = this.editor
                                        .chain()
                                        .focus()
                                        .deletePage((pos) => {
                                            position = pos;
                                        })
                                        .run();
                                    if (result) this.editor.commands.focus(position - 2);
                                }
                            },
                            title: "Delete page",
                            icon: "mdi-text-box-minus-outline",
                            showCondition: () =>
                                this.editor.isActive("pageNode"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .toggleLandscape()
                                        .run();
                                }
                            },
                            title: "Landscape page",
                            icon: "mdi-phone-rotate-landscape",
                            showCondition: () =>
                                this.editor.isActive("pageNode", {
                                    class: "page",
                                }),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .toggleVertical()
                                        .run();
                                }
                            },
                            title: "Vertical page",
                            icon: "mdi-phone-rotate-portrait",
                            showCondition: () =>
                                this.editor.isActive("pageNode", {
                                    class: "page landscape",
                                }),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    let canRun = this.editor
                                        .can()
                                        .chain()
                                        .focus()
                                        .toggleHeader({
                                            defaultHeaderContent: this
                                                .defaultHeaderContent,
                                        })
                                        .run();
                                    if (canRun)
                                        this.editor
                                            .chain()
                                            .focus()
                                            .toggleHeader({
                                                defaultHeaderContent: this
                                                    .defaultHeaderContent,
                                            })
                                            .run();
                                }
                            },
                            title: "Header",
                            icon: "mdi-page-layout-header",
                            showCondition: () =>
                                this.editor.isActive("pageNode"),
                            active: () =>
                                this.editor.isActive("pageNode", {
                                    displayHeader: true,
                                }),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    let canRun = this.editor
                                        .can()
                                        .chain()
                                        .focus()
                                        .toggleFooter({
                                            defaultFooterContent: this
                                                .defaultFooterContent,
                                        })
                                        .run();
                                    if (canRun)
                                        this.editor
                                            .chain()
                                            .focus()
                                            .toggleFooter({
                                                defaultFooterContent: this
                                                    .defaultFooterContent,
                                            })
                                            .run();
                                }
                            },
                            title: "Footer",
                            icon: "mdi-page-layout-footer",
                            showCondition: () =>
                                this.editor.isActive("pageNode"),
                            active: () =>
                                this.editor.isActive("pageNode", {
                                    displayFooter: true,
                                }),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    let canRun = this.editor
                                        .can()
                                        .chain()
                                        .focus()
                                        .toggleHeaderFooter({
                                            defaultFooterContent: this
                                                .defaultFooterContent,
                                            defaultHeaderContent: this
                                                .defaultHeaderContent,
                                        })
                                        .run();
                                    if (canRun)
                                        this.editor
                                            .chain()
                                            .focus()
                                            .toggleHeaderFooter({
                                                defaultFooterContent: this
                                                    .defaultFooterContent,
                                                defaultHeaderContent: this
                                                    .defaultHeaderContent,
                                            })
                                            .run();
                                }
                            },
                            title: "Header & footer",
                            icon: "mdi-page-layout-header-footer",
                            showCondition: () =>
                                this.editor.isActive("pageNode"),
                            divider: () => true,
                            active: () =>
                                this.editor.isActive("pageNode", {
                                    displayHeader: true,
                                }) &&
                                this.editor.isActive("pageNode", {
                                    displayFooter: true,
                                }),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    let insertingHeaderFooter = !this.editor.commands.isFullHeaderAndFooter();
                                    let result = this.editor.commands.reinsertContent();
                                    if (result) {
                                        if (insertingHeaderFooter) {
                                            this.editor.commands.fixOverflow({
                                                allowedHeight:
                                                    1100 -
                                                    this.defaultHeaderHeight -
                                                    this.defaultFooterHeight,
                                            });
                                            this.editor
                                                .chain()
                                                .focus()
                                                .toggleAllHeaderFooter({
                                                    defaultFooterContent: this
                                                        .defaultFooterContent,
                                                    defaultHeaderContent: this
                                                        .defaultHeaderContent,
                                                })
                                                .run();
                                        } else {
                                            this.editor.commands.fixOverflow({
                                                allowedHeight: 1100,
                                            });
                                        }
                                        this.updatePageIds(this.editor);

                                    }
                                }
                            },
                            title: "Full header & footer",
                            icon: "mdi-page-layout-body",
                            showCondition: () => true,
                            divider: () => false,
                        },
                    ],
                    tableMenu: [
                        {
                            command: () => { },
                            title: "Create table",
                            id: "create_table_btn",
                            gridMenu: {
                                rows: 5,
                                cols: 10,
                                close: (selection) => {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .insertFullWidthTable({
                                            rows: selection.rows,
                                            cols: selection.cols,
                                            withHeaderRow: false,
                                        })
                                        .run();
                                },
                            },
                            icon: "mdi-table-large-plus",
                            active: () => this.editor.isActive("table"),
                            divider: () => true,
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .deleteTable()
                                        .run();
                                }
                            },
                            title: "Delete table",
                            icon: "mdi-table-large-remove",
                            showCondition: () => this.editor.isActive("table"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .addColumnBefore()
                                        .run();
                                }
                            },
                            title: "Add column before",
                            icon: "mdi-table-column-plus-before",
                            showCondition: () => this.editor.isActive("table"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .addColumnAfter()
                                        .run();
                                }
                            },
                            title: "Add column after",
                            icon: "mdi-table-column-plus-after",
                            showCondition: () => this.editor.isActive("table"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .deleteColumn()
                                        .run();
                                }
                            },
                            title: "Delete column",
                            icon: "mdi-table-column-remove",
                            showCondition: () => this.editor.isActive("table"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .addRowBefore()
                                        .run();
                                }
                            },
                            title: "Add row before",
                            icon: "mdi-table-row-plus-before",
                            showCondition: () => this.editor.isActive("table"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .addRowAfter()
                                        .run();
                                }
                            },
                            title: "Add row after",
                            icon: "mdi-table-row-plus-after",
                            showCondition: () => this.editor.isActive("table"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .deleteRow()
                                        .run();
                                }
                            },
                            title: "Delete row",
                            icon: "mdi-table-row-remove",
                            showCondition: () => this.editor.isActive("table"),
                            divider: () => true,
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .mergeCells()
                                        .run();
                                }
                            },
                            title: "Merge cells",
                            icon: "mdi-table-merge-cells",
                            showCondition: () => this.editor.isActive("table"),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status) {
                                    this.editor
                                        .chain()
                                        .focus()
                                        .splitCell()
                                        .run();
                                }
                            },
                            title: "Split cell",
                            icon: "mdi-table-split-cell",
                            showCondition: () => this.editor.isActive("table"),
                            divider: () => true,
                        },
                        {
                            name: "borderLeft",
                            title: "Border left",
                            icon: "mdi-border-left-variant",
                            showCondition: () => this.editor.isActive("table"),
                            active: () =>
                                this.editor.isActive("tableCell", {
                                    borderLeft: new RegExp("[^ ]+"),
                                }),
                            command: () => {
                                if (!this.websocket.edit_status) return
                                let attrs = " "
                                const menu = this.editorMenu.tableMenu.find(menu=>menu.name=="borderLeft")
                                let isActive = menu.active()
                                if (!isActive){
                                    attrs = "1px solid"
                                }
                                this.editor
                                    .chain()
                                    .focus()
                                    .setCellAttribute("borderLeft", attrs)
                                    .run()
                            },
                        },
                        {
                            name: "borderTop",
                            title: "Border top",
                            icon:"mdi-border-top-variant",
                            showCondition: () => this.editor.isActive("table"),
                            active: () => this.editor.isActive("tableCell", {
                                borderTop: new RegExp("[^ ]+")
                            }),
                            command: () => {
                                if (!this.websocket.edit_status) return
                                let attrs = " "
                                const menu = this.editorMenu.tableMenu.find(menu=>menu.name=="borderTop")
                                let isActive = menu.active()
                                if (!isActive){
                                    attrs = "1px solid"
                                }
                                this.editor
                                    .chain()
                                    .focus()
                                    .setCellAttribute("borderTop", attrs)
                                    .run()
                            }
                        },
                        {
                            name: "borderRight",
                            title: "Border right",
                            icon: "mdi-border-right-variant",
                            showCondition: () => this.editor.isActive("table"),
                            active: () =>
                                this.editor.isActive("tableCell", {
                                    borderRight: new RegExp("[^ ]+"),
                                }),
                            command: () => {
                                if (!this.websocket.edit_status) return
                                let attrs = " "
                                const menu = this.editorMenu.tableMenu.find(menu=>menu.name=="borderRight")
                                let isActive = menu.active()
                                if (!isActive){
                                    attrs = "1px solid"
                                }
                                this.editor
                                    .chain()
                                    .focus()
                                    .setCellAttribute("borderRight", attrs)
                                    .run()
                            },
                        },
                        {
                            name: "borderBottom",
                            title: "Border bottom",
                            icon: "mdi-border-bottom-variant",
                            showCondition: () => this.editor.isActive("table"),
                            active: () =>
                                this.editor.isActive("tableCell", {
                                    borderBottom: new RegExp("[^ ]+"),
                                }),
                            command: () => {
                                if (!this.websocket.edit_status) return
                                let attrs = " "
                                const menu = this.editorMenu.tableMenu.find(menu=>menu.name=="borderBottom")
                                let isActive = menu.active()
                                if (!isActive){
                                    attrs = "1px solid"
                                }
                                this.editor
                                    .chain()
                                    .focus()
                                    .setCellAttribute("borderBottom", attrs)
                                    .run()
                                
                            },
                        },
                        {
                            name: "borderAll",
                            title: "Border all",
                            icon: "mdi-border-all",
                            showCondition: () => this.editor.isActive("table"),
                            active: () =>false,
                            command: () => {
                                if (!this.websocket.edit_status) return
                                let borders = []
                                this.editorMenu.tableMenu.forEach(menu=>{
                                    if (["borderTop", "borderBottom", "borderLeft", "borderRight"].includes(menu.name)){
                                        borders.push(menu.active())
                                    }
                                })
                                const toggle = borders.every(b=>b==true) ? ' ':'1px solid'
    
                                this.editor
                                    .chain()
                                    .focus()
                                    .setCellAttribute("borderBottom", toggle)
                                    .setCellAttribute("borderRight", toggle)
                                    .setCellAttribute("borderTop", toggle)
                                    .setCellAttribute("borderLeft", toggle)
                                    .run();
                            },
                            divider: () => true,
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status){
                                    this.editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute("verticalAlign", "middle")
                                        .run();
                                }
                            },
                            title: "Align middle",
                            icon: "mdi-align-vertical-center",
                            showCondition: () => this.editor.isActive("table"),
                            active: () =>
                                this.editor.isActive("tableCell", {
                                    verticalAlign: "middle",
                                }),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status){
                                    this.editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute("verticalAlign", "top")
                                        .run();
                                }
                            },
                            title: "Align top",
                            icon: "mdi-align-vertical-top",
                            showCondition: () => this.editor.isActive("table"),
                            active: () =>
                                this.editor.isActive("tableCell", {
                                    verticalAlign: "top",
                                }),
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status){
                                    this.editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute("verticalAlign", "bottom")
                                        .run();
                                }
                            },
                            title: "Align bottom",
                            icon: "mdi-align-vertical-bottom",
                            showCondition: () => this.editor.isActive("table"),
                            active: () =>
                                this.editor.isActive("tableCell", {
                                    verticalAlign: "bottom",
                                }),
                            divider: () => true,
                        },
                        {
                            command: () => {
                                if (this.websocket.edit_status){
                                    this.editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute(
                                            "backgroundColor",
                                            "#ffffff"
                                        )
                                        .setCellAttribute("borderColor", "#000000")
                                        .run();
                                }
                            },
                            title: "Reset color",
                            icon: "mdi-water-off",
                            showCondition: () => this.editor.isActive("table"),
                            divider: () => true,
                        },
                    ],
                    secondaryMenu: [
                        {
                            command: () => {
                                if (this.editor.isActive("paragraph"))
                                    this.editor
                                        .chain()
                                        .focus()
                                        .decreaseIndent("paragraph")
                                        .run();
                                if (this.editor.isActive("heading"))
                                    this.editor
                                        .chain()
                                        .focus()
                                        .decreaseIndent("heading")
                                        .run();
                            },
                            title: "Indent decrease",
                            icon: "mdi-format-indent-decrease",
                        },
                        {
                            command: () => {
                                if (this.editor.isActive("paragraph"))
                                    this.editor
                                        .chain()
                                        .focus()
                                        .increaseIndent("paragraph")
                                        .run();
                                if (this.editor.isActive("heading"))
                                    this.editor
                                        .chain()
                                        .focus()
                                        .increaseIndent("heading")
                                        .run();
                            },
                            title: "Indent increase",
                            icon: "mdi-format-indent-increase",
                            divider: () => true,
                        },
                        {
                            command: () => {
                                this.editor
                                    .chain()
                                    .focus()
                                    .setHorizontalRule()
                                    .run();
                            },
                            title: "Horizontal rule",
                            icon: "mdi-minus",
                            divider: () => true,
                        },
                        // {
                        //     command: () =>
                        //         this.editor
                        //             .chain()
                        //             .focus()
                        //             .setParagraph()
                        //             .run(),
                        //     active: () => this.editor.isActive("paragraph"),
                        //     title: "Paragraph",
                        //     icon: "mdi-format-pilcrow",
                        // },
                        {
                            command: () =>
                                this.editor
                                    .chain()
                                    .focus()
                                    .toggleHeading({ level: 1 })
                                    .run(),
                            active: () =>
                                this.editor.isActive("heading", { level: 1 }),
                            title: "Heading 1",
                            icon: "mdi-format-header-1",
                        },
                        {
                            command: () =>
                                this.editor
                                    .chain()
                                    .focus()
                                    .toggleHeading({ level: 2 })
                                    .run(),
                            active: () =>
                                this.editor.isActive("heading", { level: 2 }),
                            title: "Heading 2",
                            icon: "mdi-format-header-2",
                        },
                        {
                            command: () =>
                                this.editor
                                    .chain()
                                    .focus()
                                    .toggleHeading({ level: 3 })
                                    .run(),
                            active: () =>
                                this.editor.isActive("heading", { level: 3 }),
                            title: "Heading 3",
                            icon: "mdi-format-header-3",
                            divider: () => true,
                        },
                        {
                            command: () =>
                                this.editor
                                    .chain()
                                    .focus()
                                    .toggleBulletList()
                                    .run(),
                            active: () => this.editor.isActive("bulletList"),
                            title: "Bullet list",
                            icon: "mdi-format-list-bulleted",
                        },
                        {
                            command: () =>
                                this.editor
                                    .chain()
                                    .focus()
                                    .toggleOrderedList()
                                    .run(),
                            active: () => this.editor.isActive("orderedList"),
                            title: "Ordered list",
                            icon: "mdi-format-list-numbered",
                            divider: () => true,
                        },
                    ]
                },
                fontSizeSelectItems: [
                    8,
                    9,
                    10,
                    11,
                    12,
                    13,
                    14,
                    16,
                    18,
                    20,
                    22,
                    24,
                    26,
                    28,
                    36,
                    48,
                    72,
                ],
                borderWidthSelectItems: [
                    {
                        text: 0,
                        value: "0px"
                    },
                    {
                        text: 1,
                        value: '1px',
                    },
                    {
                        text: 2,
                        value: '2px',
                    },
                    {
                        text: 3,
                        value: '3px',
                    },
                    {
                        text: 4,
                        value: '4px',
                    },
                    {
                        text: 5,
                        value: '5px',
                    },
                    {
                        text: 6,
                        value: '6px',
                    },
                    {
                        text: 7,
                        value: '7px',
                    },
                    {
                        text: 8,
                        value: '8px',
                    },
                    {
                        text: 9,
                        value: '9px',
                    },
                    {
                        text: 10,
                        value: '10px',
                    },
                ],
                xbrlTags: {
                    items: [],
                    concepts: [],
                },
                savedContent: null,
                successResponse: "Initial",
                saveStatus: requestStatusEnum.IDLE,
                lastSaveTime: null,
                displayHeaderEditor: false,
                displayFooterEditor: false,
                lastSelectedFontColor: "#000000",
                textColorDisplayMenu: false,
                borderColorDisplayMenu: false,
                borderColor: "#000000",
                fillColorDisplayMenu: false,
                fillColor: "#FFFFFF",
            };
        },
        computed: {
            ...mapState(["currentPage", "scrollOffsetTop"]),
            ...mapState("xbrlData", ["displaySideMenu", "reportInfo"]),
            fontSelectedItem:{
                get() {
                    for (const font of this.fontSelectItems){
                        for (const type of this.editorConfig.FontFamily.types){
                            const attrs = this.editor.getAttributes(type)
                            if (!attrs.fontFamily) continue
                            if (attrs.fontFamily == font.text || attrs.fontFamily == font.value){
                                return font.value
                            }
                        }
                    }
                    return "Arial, Helvetica, sans-serif"
                },
                set() { },
            },
            fontSize: {
                get() {
                    for (const type of this.editorConfig.TextStyle.types){
                        const attrs = this.editor.getAttributes(type)
                        if (!attrs.fontSize) continue
                        return attrs.fontSize
                    }
                    return 12
                },
                set() { },
            },
            borderWidth: {
                get() {
                    let attrs = this.editor.getAttributes("tableCell")
                    if (attrs.borderWidth){
                        return attrs.borderWidth
                    }
                    if (attrs.borderLeft){
                        return attrs.borderLeft.split(" ")[0].trim()
                    }
                    if (attrs.borderTop){
                        return attrs.borderTop.split(" ")[0].trim()
                    }
                    if (attrs.borderRight){
                        return attrs.borderRight.split(" ")[0].trim()
                    }
                    if (attrs.borderBottom){
                        return attrs.borderBottom.split(" ")[0].trim()
                    }
                    return '0px'
                },
                set() { },
            },
            textColor: {
                get: function () {
                    for (const type of this.editorConfig.Color.types){
                        let attrs = this.editor.getAttributes(type);
                        if (!attrs.color) continue
                        if (attrs.color.match(/rgb\(\d{1,3}, \d{1,3}, \d{1,3}\)/)) {
                            return this.getHexFromRgb(attrs.color);
                        } else {
                            if (attrs.color.match(/#([a-f0-9]{2}){3}/i)) {
                                return attrs.color;
                            } else {
                                return colourNameToHex(attrs.color);
                            }
                        }
                    }
                    return "#000000"
                },
                set: function (value) {
                    if (typeof value === "object") {
                        this.lastSelectedFontColor = value.hex;
                    } else {
                        this.lastSelectedFontColor = value;
                    }
                },
            },
        },
        created() {

        },
        beforeDestroy() {

        },
        watch: {
            saveStatus: function (newVal) {
                if (newVal === requestStatusEnum.SUCCESS) {
                    let today = new Date();
                    let hours = (today.getHours() < 10 ? '0' : '') + today.getHours();
                    let minutes = (today.getMinutes() < 10 ? '0' : '') + today.getMinutes();
                    this.lastSaveTime =
                        "" + hours + ":" + minutes;
                    setTimeout(() => {
                        this.saveStatus = requestStatusEnum.IDLE;
                    }, 5000);
                }
            },
        },
        methods: {
            getHexFromRgb(rgbString) {
                let rgbColors = rgbString.slice(4, -1).split(', ');
                let rgbHex = rgbColors.map((val) => {
                    let decimalVal = parseInt(val, 10);
                    let hex = "";
                    if (decimalVal < 16) {
                        hex += "0";
                    }
                    hex += decimalVal.toString(16);
                    return hex;
                }).join("");
                return "#" + rgbHex;
            },
            getRgbFromHex(hexString) {
                let redHex = hexString[1] + hexString[2];
                let greenHex = hexString[3] + hexString[4];
                let blueHex = hexString[5] + hexString[6];
                return `rgb(${parseInt(redHex, 16)}, ${parseInt(greenHex, 16)}, ${parseInt(blueHex, 16)})`;
            },
            getRequestStatusEnum() {
                return requestStatusEnum;
            },
            onImageLoad(file) {
                this.editorHasChanges = true;
                if (file) {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        this.editor
                            .chain()
                            .focus()
                            .setImage({ src: reader.result })
                            .run();
                    };
                }
            },
            goToPage(value) {
                let numericValue = parseInt(value);
                if (
                    value &&
                    !isNaN(numericValue) &&
                    numericValue > 0 &&
                    numericValue <= this.numberOfPages
                )
                    this.$vuetify.goTo(`#page-${numericValue - 1}`, {
                        container: "#main-container",
                    });
            },
            saveContent(isSilent, ixbrl) {
                return new Promise((resolve, reject) => {
                    this.saveStatus = requestStatusEnum.LOADING;
                    let styles = document.styleSheets;
                    let pageStyles = getCssStyleSheetsBySelector(".page", styles);
                    let imageStyles = getCssStyleSheetsBySelector(
                        ".image-with-menu",
                        styles
                    );
                    let pageStyleString = getCssStyleSheetsString(pageStyles);
                    let imageStyleString = getCssStyleSheetsString(imageStyles);

                    let htmlString =
                        '<div id="editor-page"><div id="my-editor">' +
                        this.editor.getHTML() +
                        "</div></div>";
                    let htmlNode = new DOMParser().parseFromString(
                        htmlString,
                        "text/html"
                    );

                    let s = htmlNode.createElement("style");
                    s.setAttribute("type", "text/css");
                    let filteredStyles = pageStyleString.replaceAll("#editor-page", "");
                    s.append(filteredStyles);
                    htmlNode.head.append(s);

                    s = htmlNode.createElement("style");
                    s.append(imageStyleString);
                    s.setAttribute("type", "text/css");
                    htmlNode.head.append(s);

                    let xhtmlContent = new XMLSerializer().serializeToString(
                        htmlNode
                    );
                    let url = `/report/save_report_source/${this.reportInfo.id}`;
                    let requestParams = [
                        {
                            url: url,
                            method: "POST",
                            data: {
                                document: xhtmlContent,
                                report_tags: this.$store.state.xbrlData.reportTags,
                            },
                            params: {
                                ixbrl: !this.isSimpleReport && ixbrl ? true : false
                            }
                        },
                        null,
                    ];
                    if (isSilent) requestParams.push(this.successResponse);
                    
                    this.$apiHttp(...requestParams).then((res) => {
                        this.saveStatus = requestStatusEnum.SUCCESS;
                        this.setReportBaseInfo(res.data.data);
                        this.setReportTags(JSON.parse(res.data.data.xbrl_tags))
                        if (!isSilent) {
                            this.loadContent().then(() => resolve()).finally(() => {
                                this.$vuetify.goTo(this.scrollOffsetTop, {
                                    container: "#main-container",
                                });
                            });
                        } else resolve();
                    }).catch((err) => {
                        console.log(err);
                        reject();
                    });

                });
            },
            loadContent() {
                return new Promise((resolve) => {
                    this.contentLoading = true;
                    let link = this.getHtmlLink();
                    this.$apiHttp({ url: link, baseURL: "" }).then((response) => {
                        if (response.data) {
                            let thisElement = document.createElement("html");
                            thisElement.innerHTML = response.data;
                            if (!thisElement.querySelector(".page")) {
                                thisElement.style.width = "200mm";
                                let paginatedElements = [];
                                thisElement.style.visibility = "hidden";
                                document.body.appendChild(thisElement);
                                let childElements = thisElement.childNodes;
                                let groupHeight = 0;
                                let index = 0;
                                while (index < childElements.length) {
                                    let elementHeight = childElements.item(index)
                                        .offsetHeight;
                                    if (groupHeight + elementHeight > 900) {
                                        let pageElement = document.createElement(
                                            "div"
                                        );
                                        pageElement.className = "page";
                                        if (index === 0) {
                                            pageElement.appendChild(
                                                childElements[0]
                                            );
                                            pageElement.className =
                                                "page landscape";
                                            paginatedElements.push({
                                                element: pageElement.outerHTML,
                                                height: groupHeight,
                                            });
                                        } else {
                                            while (index > 0) {
                                                pageElement.appendChild(
                                                    childElements[0]
                                                );
                                                index--;
                                            }
                                            index = 0;
                                            paginatedElements.push({
                                                element: pageElement.outerHTML,
                                                height: groupHeight,
                                            });
                                        }
                                        groupHeight = 0;
                                    } else {
                                        groupHeight += elementHeight;
                                        if (index === childElements.length - 1) {
                                            let pageElement = document.createElement(
                                                "div"
                                            );
                                            pageElement.className = "page";
                                            while (index + 1 > 0) {
                                                pageElement.appendChild(
                                                    childElements[0]
                                                );
                                                index--;
                                            }
                                            paginatedElements.push({
                                                element: pageElement.outerHTML,
                                                height: groupHeight,
                                            });
                                        }
                                        index++;
                                    }
                                }
                                this.editor.chain().focus().setMeta("addToHistory", false).setContent(
                                    `${paginatedElements
                                        .map(
                                            (paginatedElement) =>
                                                paginatedElement.element
                                        )
                                        .join("")}`
                                ).run();
                                document.body.removeChild(thisElement);
                            } else {
                                let bodyStyle = thisElement.querySelector('body')?.style
                                let childrenElem = thisElement.querySelectorAll(
                                    ".page"
                                );
                                let children = Array.from(childrenElem);
                                let content = children.map((child) => {
                                    if (!bodyStyle){
                                        return child.outerHTML
                                    }
                                    for (let i = 0; i < bodyStyle.length; i++){
                                        let prop = bodyStyle.item(i)
                                        if (!child.style.getPropertyValue(prop)){
                                            child.style.setProperty(prop, bodyStyle.getPropertyValue(prop))
                                        }
                                    }
                                    return child.outerHTML
                                }).join("")
                                this.editor.chain().setMeta("addToHistory", false).setContent(
                                    content,
                                    true,
                                    { preserveWhitespace: false }
                                ).focus('start', { scrollIntoView: true }).run();
                            }
                            this.updatePageIds(this.editor);
                            this.hasContent = true;
                            this.contentLoading = false;
                            this.editorHasChanges = false;
                            resolve();
                        }
                    });
                });
            },
        },
    },
};
