import {Extension} from "@tiptap/core";

const GeneralStyle = Extension.create({
	name: "generalStyle",
	addOptions() {
		return {
			types: [],
		};
	},
	addGlobalAttributes() {
		return [
			{
				types: this.options.types,
				attributes: {
					marginLeft: {
						renderHTML: (attributes) => {
							if (attributes.marginLeft != null) {
								return {
									style: `margin-left: ${attributes.marginLeft}px`,
								};
							}
							return {};
						},
						parseHTML: (element) => {
                            if (element.style.marginLeft) {
                                return parseInt(element.style.marginLeft)
                            }
                            return null
                        }
					},
					marginRight: {
						renderHTML: (attributes) => {
							if (attributes.marginRight != null) {
								return {
									style: `margin-right: ${attributes.marginRight}px`,
								};
							}
							return {};
						},
						parseHTMLL: (element) =>{
                            if (element.style.marginRight){
                                return parseInt(element.style.marginRight)
                            }
                            return null
                        }
					},
					marginTop: {
						renderHTML: (attributes) => {
							if (attributes.marginTop != null) {
								return {
									style: `margin-top: ${attributes.marginTop}px`,
								};
							}
							return {};
						},
						parseHTML: (element) => {
                            if (element.style.marginTop){
                                return parseInt(element.style.marginTop)
                            }
                            return null
                        }
					},
					marginBottom: {
						renderHTML: (attributes) => {
							if (attributes.marginBottom != null) {
								return {
									style: `margin-bottom: ${attributes.marginBottom}px`,
								};
							}
							return {};
						},
						
                        parseHTML: (element) => {
                            if (element.style.marginBottom){
                                return parseInt(element.style.marginBottom)
                            }
                            return null
                        }
					},
					paddingLeft: {
						renderHTML: (attributes) => {
							if (attributes.paddingLeft != null) {
								return {
									style: `padding-left: ${attributes.paddingLeft}px`,
								};
							}
							return {};
						},
						parseHTML: (element) => {
                            if (element.style.paddingLeft){
                                return parseInt(element.style.paddingLeft)
                            }
                            return null
                        }
                        
					},
					paddingRight: {
						renderHTML: (attributes) => {
							if (attributes.paddingRight != null) {
								return {
									style: `padding-right: ${attributes.paddingRight}px`,
								};
							}
							return {};
						},
						parseHTML: (element) =>{
                            if(element.style.paddingRight){
                                return parseInt(element.style.paddingRight)
                            }

							return null
                        }
					},
					paddingTop: {
						renderHTML: (attributes) => {
							if (attributes.paddingTop != null) {
								return {
									style: `padding-top: ${attributes.paddingTop}px`,
								};
							}
							return {};
						},
						parseHTML: (element) => {
                            if(element.style.paddingTop){
                                return parseInt(element.style.paddingTop)
                            }
                            return null
                        }
					},
					paddingBottom: {
						renderHTML: (attributes) => {
							if (attributes.paddingBottom != null) {
								return {
									style: `padding-bottom: ${attributes.paddingBottom}px`,
								};
							}
							return {};
						},
						parseHTML: (element) => {
                            if(element.style.paddingBottom){
                                return parseInt(element.style.paddingBottom)
                            }
                            return null
                        }
					},
				},
			},
		];
	},
});

export default GeneralStyle;
