import {Extension} from "@tiptap/core";

const CustomTextStyle = Extension.create({
    name: "customTextStyle",
    addOptions() {
        return {
            types: ["textStyle"],
        };
    },
    addGlobalAttributes(){
        return [
			{
				types: this.options.types,
				attributes: {
					fontSize: {
						renderHTML: (attributes) => {
							if (attributes.fontSize) {
								return {
									style: `font-size: ${attributes.fontSize}pt`,
								};
							}
							return {};
						},
						parseHTML: element => parseInt(element.style.fontSize) || null,
					},
				},
			},
		]
    }
});

export default CustomTextStyle