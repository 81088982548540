<template>
    <v-menu v-bind="$attrs" v-on="$listeners" v-model="opened">
        <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
        <template v-for="(_, name) of $scopedSlots" v-slot:[name]="scope">
            <slot :name="name" v-bind="scope"/>
        </template>
        <v-card tile flat>
            <div class="v-messages v-messages__message pa-2" v-if="hint">Rows: {{selection.rows}} Cols: {{selection.cols}}</div>
            <table :style="selectedColor">
                <colgroup>
                    <col style="width: 15px;" v-for="col of cells[0].length" :key="`h-${col}`">
                </colgroup>
                <tbody>
                    <tr v-for="(row, ri) in cells" :key="`row-${ri}`" style="height: 15px" >
                        <td v-for="(cell, ci) in row" :key="`item-${ci}-${ri}`"
                            :class="{'full-border': true, selected: cell.selected}"
                            @mouseenter="selectCells(cell)"
                            >
                        </td>
                    </tr>
                </tbody>
            </table>
        </v-card>
    </v-menu>
    
</template>

<script>
export default {
    name: "GridMenu",
    props: {
        cols:{
            type: Number,
            default: 5
        },
        rows:{
            type: Number,
            default: 5
        },
        color:{
            type: String,
            default: "rgba(255, 166, 0, 0.849)"
        },
        hint:{
            type:Boolean,
            default: true
        },
    },
    created(){
        for (let r of Array(this.rows).keys()){
            this.$set(this.cells, r, []);
            for (let c of Array(this.cols).keys()){
                this.$set(this.cells[r], c, {selected: false, row:r, col:c});
            }
        }
    },
    mounted(){
        const activator = document.getElementById(this.$attrs.activator.replace("#", ""))
        //check if the activator has the click event listener
        if (activator){
            activator.addEventListener("click", () => {
                this.opened = true;
            }, {bubble: true})
        }
    },
    data(){
        return{
            cells: [[]],
            opened: false,
            selection: {},
        }
    },
    methods:{
        selectCells(cell){
            for (let row of this.cells){
                for (let cell of row){
                    cell.selected = false;
                }
            }
            for (let r = 0; r <= cell.row; r++){
                for(let c = 0; c <= cell.col; c++){
                    this.cells[r][c].selected = true;
                }
            }
            this.selection = {
                rows: cell.row + 1,
                cols: cell.col + 1
            };
        },
    },
    computed:{
        selectedColor(){
            return {
                "--selected-color": this.color
            }
        }
    },
    watch:{
        opened(val){
            if (!val){
                //cand inchidem
                this.$emit("close", this.selection);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    :deep(){
        .full-border{
            border: 1px solid black;
        }

        .selected{
            background-color: var(--selected-color);
        }
    }
</style>