import { render, staticRenderFns } from "./TaxonomiesMenu.vue?vue&type=template&id=c1df1354&scoped=true"
import script from "./TaxonomiesMenu.vue?vue&type=script&lang=js"
export * from "./TaxonomiesMenu.vue?vue&type=script&lang=js"
import style0 from "./TaxonomiesMenu.vue?vue&type=style&index=0&id=c1df1354&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1df1354",
  null
  
)

export default component.exports