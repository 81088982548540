import { Node } from "@tiptap/core";

const PageNode = Node.create({
    name: "pageNode",
    selectable: false,
    content: "pageHeader{0,1} block+ pageFooter{0,1}",
    defining: true,
    isolating: true,
    addAttributes: () => {
        return {
            class: {
                default: "page",
            },
            "page-id": {
                renderHTML: (attributes) => {
                    if (
                        attributes["page-id"] !== null &&
                        attributes["page-id"] !== undefined
                    ) {
                        if (typeof attributes['page-id'] === 'number') {
                            return {
                                id: `page-${attributes["page-id"]}`,
                            };
                        } else {
                            return {
                                id: attributes["page-id"],
                            };
                        }
                    }
                },
                parseHTML: (el) => el.getAttribute("id"),
            },
            displayHeader: {
                default: false,
                renderHTML: (attributes) => {
                    return attributes.displayHeader
                        ? {
                            "data-displayheader": attributes.displayHeader,
                        }
                        : null;
                },
                parseHTML: (el) => {
                    let htmlAttribute = el.getAttribute("data-displayheader");
                    let nodeAttribute = htmlAttribute === "true" ? true : false;
                    return nodeAttribute;
                },
            },
            displayFooter: {
                default: false,
                renderHTML: (attributes) => {
                    return attributes.displayFooter
                        ? {
                            "data-displayfooter": attributes.displayFooter,
                        }
                        : null;
                },
                parseHTML: (el) => {
                    let htmlAttribute = el.getAttribute("data-displayfooter");
                    let nodeAttribute = htmlAttribute === "true" ? true : false;
                    return nodeAttribute;
                },
            },
        };
    },
    renderHTML({ HTMLAttributes }) {
        return ["div", HTMLAttributes, 0];
    },
    parseHTML() {
        return [
            {
                tag: "div",
                getAttrs: (node) => node.classList.contains("page") ? {} : false,
            },
        ];
    },
    addCommands() {
        return {
            wrapPageNode: () => ({ commands, editor }) => {
                if (editor.isActive("pageNode")) {
                    return false;
                }
                return commands.toggleWrap("pageNode");
            },
            addPage: (cb) => ({ tr, state, chain }) => {
                let { selection } = state;
                let node = tr.doc;
                let resolvedPos = node.resolve(selection.from);
                let afterPagePos = resolvedPos.after(1);
                cb(afterPagePos);
                return chain()
                    .insertContentAt(afterPagePos, [
                        {
                            type: "pageNode",
                            content: [{ type: "paragraph" }],
                        },
                    ])
                    .run();
            },
            deletePage: (cb) => ({ tr, state, commands, dispatch }) => {
                let { selection } = state;
                let node = tr.doc;
                let resolvedPos = node.resolve(selection.from);
                let afterPagePos = resolvedPos.after(1);
                let beforePagePos = resolvedPos.before(1);
                cb(beforePagePos);
                return commands.command(({ tr }) => {
                    if (dispatch) {
                        tr.delete(beforePagePos, afterPagePos);
                    }
                    return true;
                });
            },
            toggleLandscape: () => ({ commands, editor }) => {
                if (editor.isActive("pageNode")) {
                    return commands.updateAttributes("pageNode", {
                        class: "page landscape",
                    });
                } else {
                    return false;
                }
            },
            toggleVertical: () => ({ commands, editor }) => {
                if (editor.isActive("pageNode")) {
                    return commands.updateAttributes("pageNode", {
                        class: "page",
                    });
                } else {
                    return false;
                }
            },
            toggleHeaderFooter: (payload) => ({ state, chain, dispatch }) => {
                let { doc, selection } = state;
                let resolvedPagePosition = doc.resolve(selection.from);
                let absoluteBeforeHeaderPos = resolvedPagePosition.start(1);
                let absoluteAfterFooterPos = resolvedPagePosition.end(1);

                let { node: pageNode } = doc.childAfter(
                    absoluteBeforeHeaderPos
                );

                let hasHeader = pageNode.attrs.displayHeader;
                let hasFooter = pageNode.attrs.displayFooter;

                let { index: pageId } = doc.childAfter(absoluteBeforeHeaderPos);

                if (!hasHeader && !hasFooter) {
                    return chain()
                        .updateAttributes("pageNode", {
                            displayHeader: true,
                        })
                        .updateAttributes("pageNode", {
                            displayFooter: true,
                        })
                        .insertContentAt(absoluteAfterFooterPos, [
                            {
                                type: "pageFooter",
                                content:
                                    payload &&
                                        payload.defaultFooterContent &&
                                        payload.defaultFooterContent !== ``
                                        ? [
                                            ...payload.defaultFooterContent,
                                            {
                                                type: "paragraph",
                                                content: [
                                                    {
                                                        type: "text",
                                                        text: `Pagina ${pageId +
                                                            1}`,
                                                    },
                                                ],
                                                attrs: {
                                                    textAlign: "center",
                                                },
                                            },
                                        ]
                                        : [
                                            {
                                                type: "paragraph",
                                                content: [
                                                    {
                                                        type: "text",
                                                        text: `Pagina ${pageId +
                                                            1}`,
                                                    },
                                                ],
                                                attrs: {
                                                    textAlign: "center",
                                                },
                                            },
                                        ],
                            },
                        ])
                        .insertContentAt(absoluteBeforeHeaderPos, [
                            {
                                type: "pageHeader",
                                content:
                                    payload &&
                                        payload.defaultHeaderContent &&
                                        payload.defaultHeaderContent !== ``
                                        ? payload.defaultHeaderContent
                                        : [{ type: "paragraph" }],
                            },
                        ])
                        .run();
                } else if (hasHeader && hasFooter) {
                    let resolvedPosInHeader = doc.resolve(
                        absoluteBeforeHeaderPos + 1
                    );
                    let absoluteAfterHeaderPos = resolvedPosInHeader.after(2);

                    let resolvedPosInFooter = doc.resolve(
                        absoluteAfterFooterPos - 1
                    );
                    let absoluteBeforeFooterPos = resolvedPosInFooter.before(2);
                    return chain()
                        .updateAttributes("pageNode", {
                            displayHeader: false,
                        })
                        .updateAttributes("pageNode", {
                            displayFooter: false,
                        })
                        .command(({ tr }) => {
                            if (dispatch) {
                                tr.delete(
                                    absoluteBeforeFooterPos,
                                    absoluteAfterFooterPos
                                );
                            }
                            return true;
                        })
                        .command(({ tr }) => {
                            if (dispatch) {
                                tr.delete(
                                    absoluteBeforeHeaderPos,
                                    absoluteAfterHeaderPos
                                );
                            }
                            return true;
                        })
                        .run();
                } else {
                    if (!hasHeader) {
                        return chain()
                            .updateAttributes("pageNode", {
                                displayHeader: true,
                            })
                            .insertContentAt(absoluteBeforeHeaderPos, [
                                {
                                    type: "pageHeader",
                                    content:
                                        payload &&
                                            payload.defaultHeaderContent &&
                                            payload.defaultHeaderContent !== ``
                                            ? payload.defaultHeaderContent
                                            : [{ type: "paragraph" }],
                                },
                            ])
                            .run();
                    } else {
                        return chain()
                            .updateAttributes("pageNode", {
                                displayFooter: true,
                            })
                            .insertContentAt(absoluteAfterFooterPos, [
                                {
                                    type: "pageFooter",
                                    content:
                                        payload &&
                                            payload.defaultFooterContent &&
                                            payload.defaultFooterContent !== ``
                                            ? [
                                                ...payload.defaultFooterContent,
                                                {
                                                    type: "paragraph",
                                                    content: [
                                                        {
                                                            type: "text",
                                                            text: `Pagina ${pageId +
                                                                1}`,
                                                        },
                                                    ],
                                                    attrs: {
                                                        textAlign: "center",
                                                    },
                                                },
                                            ]
                                            : [
                                                {
                                                    type: "paragraph",
                                                    content: [
                                                        {
                                                            type: "text",
                                                            text: `Pagina ${pageId +
                                                                1}`,
                                                        },
                                                    ],
                                                    attrs: {
                                                        textAlign: "center",
                                                    },
                                                },
                                            ],
                                },
                            ])
                            .run();
                    }
                }
            },
            toggleHeader: (payload) => ({ state, chain, dispatch }) => {
                let { doc, selection } = state;
                let resolvedPagePosition = doc.resolve(selection.from);
                let absoluteBeforeHeaderPos = resolvedPagePosition.start(1);

                let { node: pageNode } = doc.childAfter(
                    absoluteBeforeHeaderPos
                );
                let hasHeader = pageNode.attrs.displayHeader;

                if (!hasHeader) {
                    return chain()
                        .updateAttributes("pageNode", {
                            displayHeader: true,
                        })
                        .insertContentAt(absoluteBeforeHeaderPos, [
                            {
                                type: "pageHeader",
                                content:
                                    payload &&
                                        payload.defaultHeaderContent &&
                                        payload.defaultHeaderContent !== ``
                                        ? payload.defaultHeaderContent
                                        : [{ type: "paragraph" }],
                            },
                        ])
                        .run();
                } else {
                    let resolvedPosInHeader = doc.resolve(
                        absoluteBeforeHeaderPos + 1
                    );
                    let absoluteAfterHeaderPos = resolvedPosInHeader.after(2);
                    return chain()
                        .updateAttributes("pageNode", {
                            displayHeader: false,
                        })
                        .command(({ tr }) => {
                            if (dispatch) {
                                tr.delete(
                                    absoluteBeforeHeaderPos,
                                    absoluteAfterHeaderPos
                                );
                            }
                            return true;
                        })
                        .run();
                }
            },
            toggleFooter: (payload) => ({ state, chain, dispatch }) => {
                let { doc, selection } = state;
                let resolvedPagePosition = doc.resolve(selection.from);
                let absoluteAfterFooterPos = resolvedPagePosition.end(1);

                let { index: pageId, node: pageNode } = doc.childAfter(
                    absoluteAfterFooterPos
                );

                pageId;

                if (!pageNode.attrs.displayFooter) {
                    return chain()
                        .updateAttributes("pageNode", {
                            displayFooter: true,
                        })
                        .insertContentAt(absoluteAfterFooterPos, [
                            {
                                type: "pageFooter",
                                content:
                                    payload &&
                                        payload.defaultFooterContent &&
                                        payload.defaultFooterContent !== ``
                                        ? [
                                            ...payload.defaultFooterContent,
                                            {
                                                type: "paragraph",
                                                content: [
                                                    {
                                                        type: "text",
                                                        text: `Pagina ${pageId +
                                                            1}`,
                                                    },
                                                ],
                                                attrs: {
                                                    textAlign: "center",
                                                },
                                            },
                                        ]
                                        : [
                                            {
                                                type: "paragraph",
                                                content: [
                                                    {
                                                        type: "text",
                                                        text: `Pagina ${pageId +
                                                            1}`,
                                                    },
                                                ],
                                                attrs: {
                                                    textAlign: "center",
                                                },
                                            },
                                        ],
                            },
                        ])
                        .run();
                } else {
                    let resolvedPosInFooter = doc.resolve(
                        absoluteAfterFooterPos - 1
                    );
                    let absoluteBeforeFooterPos = resolvedPosInFooter.before(2);
                    return chain()
                        .updateAttributes("pageNode", {
                            displayFooter: false,
                        })
                        .command(({ tr }) => {
                            if (dispatch) {
                                tr.delete(
                                    absoluteBeforeFooterPos,
                                    absoluteAfterFooterPos
                                );
                            }
                            return true;
                        })
                        .run();
                }
            },
        };
    },
});

export default PageNode;
