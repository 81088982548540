import TableCell from "@tiptap/extension-table-cell";

const CustomTableCell = TableCell.extend({
    addAttributes: () => {
        return {
            ...TableCell.config.addAttributes(),
            colwidth: {
                default: [25],
                renderHTML: (attributes) => {
                    return attributes.colwidth
                        ? {
                            "data-colwidth": attributes.colwidth.join(","),
                        }
                        : null;
                },
                parseHTML: (el) => {
                    let colWidth = el.getAttribute("data-colwidth");
                    let colWidthArray = [25];
                    if (colWidth !== undefined && colWidth !== null){
                        colWidthArray = el
                            .getAttribute("data-colwidth")
                            .split(",")
                            .map((width) => parseInt(width));
                    }else if (el.style.width){
                        colWidthArray = [parseInt(el.style.width)];
                    }
                    
                    return colWidthArray;
                },
            },
            borderLeft:{
                default: "1px solid",
                parseHTML(el){
                    // prima data ne uitam pe <td>
                    let borderLeft = el.style.borderLeft
                    if (!borderLeft){
                        if (el.style.borderLeftWidth){
                            borderLeft = `${el.style.borderLeftWidth} ${el.style.borderLeftStyle} ${el.style.borderLeftColor}`
                        }
                    }

                    if (!borderLeft){
                        //ne uitam daca are border
                        if (el.style.border){
                            borderLeft = el.style.border
                        }
                    }

                    //apoi ne uitam si pe <table>,
                    //dar doar daca td-ul face parte din prima coloana
                    if (!borderLeft){
                        let table = el.closest("table")
                        if (table && el.cellIndex === 0){
                            borderLeft = table.style.borderLeft
                            if (!borderLeft){
                                if (table.style.borderLeftWidth){
                                    borderLeft = `${table.style.borderLeftWidth} ${table.style.borderLeftStyle} ${table.style.borderLeftColor}`
                                }
                            }
                            if (!borderLeft){
                                if (table.style.border){
                                    borderLeft = table.style.border
                                }
                            }
                        }
                    }

                    return borderLeft.trim()
                },
                renderHTML(attrs){
                    if (attrs.borderLeft){
                        return {
                            style: `border-left: ${attrs.borderLeft}`,
                        };
                    }
                    return {};
                }
            },
            borderRight:{
                default: "1px solid",
                parseHTML(el){
                    // prima data ne uitam pe <td>
                    let borderRight = el.style.borderRight
                    if (!borderRight){
                        if (el.style.borderRightWidth){
                            borderRight = `${el.style.borderRightWidth} ${el.style.borderRightStyle} ${el.style.borderRightColor}`
                        }
                    }

                    if (!borderRight){
                        //ne uitam daca are border
                        if (el.style.border){
                            borderRight = el.style.border
                        }
                    }

                    //apoi ne uitam si pe <table>,
                    //dar doar daca td-ul face parte din ultima coloana
                    if (!borderRight){
                        let table = el.closest("table")
                        if (table && el.cellIndex === table.rows[0].cells.length - 1){
                            borderRight = table.style.borderRight
                            if (!borderRight){
                                if (table.style.borderRightWidth){
                                    borderRight = `${table.style.borderRightWidth} ${table.style.borderRightStyle} ${table.style.borderRightColor}`
                                }
                            }
                            if (!borderRight){
                                if (table.style.border){
                                    borderRight = table.style.border
                                }
                            }
                        }
                    }

                    return borderRight.trim()
                },
                renderHTML(attrs){
                    if (attrs.borderRight){
                        return {
                            style: `border-right: ${attrs.borderRight}`,
                        };
                    }
                    return {};
                }
            },
            borderTop:{
                default: "1px solid",
                parseHTML(el){
                    // prima data ne uitam pe <td>
                    let borderTop = el.style.borderTop
                    if (!borderTop){
                        if (el.style.borderTopWidth){
                            borderTop = `${el.style.borderTopWidth} ${el.style.borderTopStyle} ${el.style.borderTopColor}`
                        }
                    }

                    if (!borderTop){
                        //ne uitam daca are border
                        if (el.style.border){
                            borderTop = el.style.border
                        }
                    }

                    //apoi ne uitam si pe <table>,
                    //dar doar daca td-ul face parte din prima linie
                    if (!borderTop){
                        let table = el.closest("table")
                        if (table && el.parentElement.rowIndex === 0){
                            borderTop = table.style.borderTop
                            if (!borderTop){
                                if (table.style.borderTopWidth){
                                    borderTop = `${table.style.borderTopWidth} ${table.style.borderTopStyle} ${table.style.borderTopColor}`
                                }
                            }
                            if (!borderTop){
                                if (table.style.border){
                                    borderTop = table.style.border
                                }
                            }
                        }
                    }

                    return borderTop.trim()
                },
                renderHTML(attrs){
                    if (attrs.borderTop){
                        return {
                            style: `border-top: ${attrs.borderTop}`,
                        };
                    }
                    return {};
                }
            },
            borderBottom:{
                default: "1px solid",
                parseHTML(el){
                    // prima data ne uitam pe <td>
                    let borderBottom = el.style.borderBottom
                    if (!borderBottom){
                        if (el.style.borderBottomWidth){
                            borderBottom = `${el.style.borderBottomWidth} ${el.style.borderBottomStyle} ${el.style.borderBottomColor}`
                        }
                    }

                    if (!borderBottom){
                        //ne uitam daca are border
                        if (el.style.border){
                            borderBottom = el.style.border
                        }
                    }

                    //apoi ne uitam si pe <table>,
                    //dar doar daca td-ul face parte din ultima linie
                    if (!borderBottom){
                        let table = el.closest("table")
                        if (table && el.parentElement.rowIndex === table.rows.length - 1){
                            borderBottom = table.style.borderBottom
                            if (!borderBottom){
                                if (table.style.borderBottomWidth){
                                    borderBottom = `${table.style.borderBottomWidth} ${table.style.borderBottomStyle} ${table.style.borderBottomColor}`
                                }
                            }
                            if (!borderBottom){
                                if (table.style.border){
                                    borderBottom = table.style.border
                                }
                            }
                        }
                    }

                    return borderBottom
                },
                renderHTML(attrs){
                    if (attrs.borderBottom){
                        return {
                            style: `border-bottom: ${attrs.borderBottom}`,
                        };
                    }
                    return {};
                }
            },
            borderWidth: {
                default: "1px",
                parseHTML(el){
                    let borderWidth = el.style.borderWidth
                    if (!borderWidth){
                        if (el.style.border){
                            borderWidth = el.style.border.split(" ")[0]
                        }
                    }
                    return borderWidth.trim()
                },
                renderHTML(attrs){
                    if (attrs.borderWidth){
                        return {
                            style: `border-width: ${attrs.borderWidth}`,
                        };
                    }
                    return {};
                }
            },
            borderColor:{
                default: "#000000",
                parseHTML(el){
                    let color = el.style.borderColor
                    if (!color){
                        color = el.style.border.split(" ")[2]
                    }
                    return color?.trim() || ""
                },
                renderHTML(attrs){
                    if (!attrs.borderColor) return {}
                    return {
                        style: `border-color: ${attrs.borderColor}`
                    }
                }
            },
            backgroundColor:{
                parseHTML(el){
                    return el.style.backgroundColor
                },
                renderHTML(attrs){
                    if (!attrs.backgroundColor) return {}
                    return {
                        style: `background-color: ${attrs.backgroundColor}`
                    }
                }
            }
        };
    },
});

export default CustomTableCell;