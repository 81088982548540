import Paragraph from "@tiptap/extension-paragraph";

const CustomParagraph = Paragraph.extend({
    addAttributes: () => {
        return {
            indent: {
                default: null,
                renderHTML: (attributes) => {
                    return attributes.indent
                        ? {
                              style: `margin-left: ${attributes.indent * 14}px`,
                          }
                        : null;
                },
                parseHTML: (el) => {
                    let ml = 0
                    let ti = 0
                    if (el.style.marginLeft){
                        ml = parseInt(el.style.marginLeft)
                    }
                    if (el.style.textIndent){
                        ti = parseInt(el.style.textIndent)
                    }

                    return Math.round((ml + ti) / 14)
                },
            },
        };
    },
    addCommands() {
        return {
            increaseIndent: (nodeType) => ({ editor, chain}) => {
                let { indent } = editor.getAttributes(nodeType);
                if (indent) { 
                    if (indent < 15){
                        ++indent;
                    }
                }else{
                    indent = 1;
                }
                return chain().updateAttributes(nodeType, { indent});
            },
            decreaseIndent: (nodeType) => ({ editor, chain}) => {
                let { indent } = editor.getAttributes(nodeType);
                if (indent && indent > 0) { 
                    --indent;
                    return chain().updateAttributes(nodeType, { indent});
                }else{
                    return false;
                }
            }
        }
    } 
});

export default CustomParagraph;
