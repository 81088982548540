export function processAlert(alert) {
    if (!alert) {
        return { visible: false };
    }

    if (!alert.severity && alert.type) {
        alert.severity = alert.type;
        delete alert.type;
    }

    if (!alert.severity) {
        alert.severity = "success";
    }

    if (!alert.message || alert.message.length == 0) {
        return { visible: false };
    }

    if (!alert.summary) {
        alert.summary =
            alert.severity.charAt(0).toUpperCase() + alert.severity.slice(1);
        if (alert.severity == "error" && alert.statusCode) {
            alert.summary += ` (code ${alert.statusCode})`;
        }
    }

    if (!alert.life) {
        alert.life = 5000;
    }

    alert.visible = true;
    return alert;
}

export function getCookie(name) {
    var r = document.cookie.match("\\b" + name + "=([^;]*)\\b");
    return r ? r[1] : undefined;
}

export const requestStatusEnum = Object.freeze({
    SUCCESS: "success",
    ERROR: "error",
    LOADING: "loading",
    IDLE: "idle",
});

export function decodeJWT(token) {
    let base64Url = token.access_token.split('.')[1];
    let base64 = base64Url.replace('-', '+').replace('_', '/');
    let decodedData = JSON.parse(Buffer.from(base64, 'base64').toString('binary'));
    return decodedData
}

export function downloadFileFromResponse(res){
    let blob = new Blob([res.data], {
        type: res.headers["content-type"],
    });
    let url = window.URL.createObjectURL(blob);

    let fileLink = document.createElement("a");
    fileLink.href = url;

    let filename = "";
    let disposition = res.headers["content-disposition"];

    if (disposition && disposition.indexOf("attachment") !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, "").replace(/^utf-8/, "");
            filename = decodeURIComponent(filename);
        }
    }

    fileLink.setAttribute("download", filename);
    document.body.appendChild(fileLink);

    fileLink.click();
    fileLink.remove();
    URL.revokeObjectURL(url);
}

export function openFileInNewTab(res){
    let blob = new Blob([res.data], {
        type: res.headers["content-type"],
    });
    let url = window.URL.createObjectURL(blob);
    let openedWindow = window.open(url, "_blank");
    openedWindow.addEventListener("beforeunload", () => {
        URL.revokeObjectURL(url);
    })
}

export function openViewerFileInNewTab(htmlFile, jsFile) {
    
    let htmlUrl = window.URL.createObjectURL(htmlFile);
    let jsUrl = window.URL.createObjectURL(jsFile);
    let openedWindow = window.open(htmlUrl, "_blank");
    
    if (openedWindow){
        openedWindow.onload = function() {
            let jsScript = openedWindow.document.createElement("script");
            jsScript.src = jsUrl;
            jsScript.type = "text/javascript";
            openedWindow.document.body.appendChild(jsScript);
        }
        openedWindow.onbeforeunload = () => {
        }
    }else{
        URL.revokeObjectURL(htmlUrl);
        URL.revokeObjectURL(jsUrl);
    }
}

export async function generateUniqueIdentifier(appendedString) {
    const encoder = new TextEncoder();
    let timestamp = Date.now().toString();
    let str = timestamp.concat(appendedString);
    let encodedStr = encoder.encode(str);
    const hash = await crypto.subtle.digest('SHA-256', encodedStr);
    const hashArray = Array.from(new Uint8Array(hash));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    
    return hashHex;
}

export function debounce(timeoutId, debounceTime, callback, predicate, ...params){
    if (predicate && predicate()){
        clearTimeout(timeoutId);
        timeoutId = setTimeout( function () {
            callback(params);
        }, debounceTime)
    }
    return timeoutId;
}

export function createEnum(values) {
	const enumObject = {}
	for (const val of values) {
		enumObject[val] = val
	}
	return Object.freeze(enumObject)
}
