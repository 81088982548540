const editorConfig = Object.freeze({
    TextAlign: {
        types: ["textStyle", "paragraph", "heading", "listItem"]
    },
    GeneralStyle: {
        types: ["paragraph", "heading", "listItem", "bulletList", "orderedList", "tableCell", "table", "pageNode"]
    },
    TextStyle: {
        types: ["textStyle", "paragraph", "heading", "listItem", "pageNode"]
    },
    FontFamily: {
        types: ["textStyle", "paragraph", "heading", "listItem", "pageNode"]
    },
    Color: {
        types: ["textStyle", "paragraph", "heading"]
    }
})

export default editorConfig