<template>
    <div class="uv-page" id="editor-page">
        <v-dialog :value="!hasContent || contentLoading" persistent width="300">
            <v-card color="primary">
                <v-card-text>
                    <span class="white--text">Working</span>
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-card outlined v-if="hasContent" style="background-color: #555">
            <div class="editor-menu">
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            <div>
                                <div class="d-flex flex-wrap align-center">
                                    <template v-for="(item, idx) in editorMenu.mainMenu">
                                        <uv-button
                                            :key="`item_${idx}`"
                                            v-if="!item.showCondition || item.showCondition()"
                                            icon
                                            :height="item.height || 32"
                                            :width="item.width || 32"
                                            @click.native.stop="item.command(item)"
                                            :loading="item.busy"
                                            :disabled="item.busy || !websocket.edit_status"
                                            :title="item.title"
                                            :btn-classes="{
                                                'mx-1': true,
                                                'is-active': item.active && item.active(),
                                            }"
                                        >
                                            <v-icon size="20" :color="item.color || 'black'">{{ item.icon }}</v-icon>
                                        </uv-button>
                                        <v-divider
                                            class="mx-2"
                                            vertical
                                            v-if="item.divider && item.divider()"
                                            :key="`divider_${idx}`"
                                        ></v-divider>
                                    </template>
                                    <v-dialog
                                        transition="dialog-top-transition"
                                        max-width="1200px"
                                        scrollable
                                        @input="(value) => (displayHeaderEditor = value)"
                                    >
                                        <template #activator="{on,attrs}">
                                            <uv-button
                                                :disabled="!websocket.edit_status"
                                                v-on="on"
                                                v-bind="attrs"
                                                icon
                                                height="32"
                                                width="32"
                                                title="Edit header"
                                            >
                                                <v-icon size="20" color="black">mdi-application-edit</v-icon>
                                            </uv-button>
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0" style="height: 600px">
                                                <header-footer-editor
                                                    :header="true"
                                                    @input="onHeaderContentChange"
                                                    :is-visible="displayHeaderEditor"
                                                ></header-footer-editor>
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                    <v-dialog
                                        transition="dialog-top-transition"
                                        max-width="1200px"
                                        scrollable
                                        @input="(value) => (displayFooterEditor = value)"
                                    >
                                        <template #activator="{on,attrs}">
                                            <uv-button
                                                :disabled="!websocket.edit_status"
                                                v-on="on"
                                                v-bind="attrs"
                                                icon
                                                height="32"
                                                width="32"
                                                title="Edit footer"
                                            >
                                                <v-icon size="20" color="black">mdi-application-edit</v-icon>
                                            </uv-button>
                                        </template>
                                        <v-card>
                                            <v-card-text class="pa-0" style="height: 600px">
                                                <header-footer-editor
                                                    :footer="true"
                                                    @input="onFooterContentChange"
                                                    :is-visible="displayFooterEditor"
                                                >
                                                </header-footer-editor> </v-card-text
                                        ></v-card>
                                    </v-dialog>
                                    <v-divider class="mx-2" vertical></v-divider>
                                    <v-select
                                        style="width: 150px"
                                        v-model="fontSelectedItem"
                                        :items="fontSelectItems"
                                        dense
                                        label="Font"
                                        solo
                                        hide-details
                                        @change="onFontChange"
                                        @click.native.stop
                                        :disabled="!websocket.edit_status"
                                    >
                                        <template #item="{item}">
                                            <span
                                                :style="{
                                                    fontFamily: item.value,
                                                }"
                                                >{{ item.text }}</span
                                            >
                                        </template>
                                    </v-select>
                                    <v-combobox
                                        class="ml-1"
                                        style="width: 25px"
                                        v-model="fontSize"
                                        :items="fontSizeSelectItems"
                                        dense
                                        label="Size"
                                        solo
                                        hide-details
                                        @change="onFontSizeChange"
                                        @click.native.stop
                                        :disabled="!websocket.edit_status"
                                    >
                                    </v-combobox>
                                    <v-item-group class="elevation-2 mx-1 v-btn-toggle v-btn-toggle--dense">
                                        <uv-button text
                                            :disabled="!websocket.edit_status"
                                            @click.native.stop="handleApplyFontColor(lastSelectedFontColor)"
                                            title="Apply font color"
                                        >
                                            <v-icon
                                                size="20"
                                                color="black"
                                                :style="{
                                                    borderBottom: `5px solid ${lastSelectedFontColor}`,
                                                    marginBottom: '-5px',
                                                }"
                                                >mdi-format-color-text</v-icon
                                            ></uv-button
                                        >
                                        <v-menu :close-on-content-click="false" v-model="textColorDisplayMenu">
                                            <template #activator="{ attrs, on }">
                                                <uv-button text
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    title="Select font color"
                                                    class="px-0"
                                                    width="1.5em"
                                                    min-width="1.5em"
                                                    :disabled="!websocket.edit_status"
                                                    @click.native.stop="textColorDisplayMenu = true"
                                                >
                                                    <v-icon size="20" color="black">mdi-chevron-down</v-icon>
                                                </uv-button>
                                            </template>
                                            <v-color-picker
                                                :disabled="!websocket.edit_status"
                                                dot-size="25"
                                                show-swatches
                                                :swatches="colorSwatches"
                                                class="rounded-0"
                                                swatches-max-height="200"
                                                v-model="lastSelectedFontColor"
                                                @dblclick.native="handleApplyFontColor(lastSelectedFontColor);textColorDisplayMenu = false"
                                                @keyup.native.enter="handleApplyFontColor(lastSelectedFontColor);textColorDisplayMenu = false">
                                            </v-color-picker>
                                            <div class="d-flex justify-space-between py-1 align-center" style="background-color: white">
                                                <div class="justify-start px-1">
                                                    <uv-button
                                                        elevation="0"
                                                        small
                                                        :color="lastSelectedFontColor"
                                                        @click.native.stop="handleApplyFontColor(lastSelectedFontColor);textColorDisplayMenu = false"
                                                        title="User color">
                                                            <v-icon
                                                                size="20"
                                                                class="outlined rounded-pill"
                                                                style="background-color: white; outline: 1px solid black"
                                                                color="black">
                                                                   mdi-format-color-text
                                                            </v-icon>
                                                    </uv-button>
                                                    <uv-button
                                                        class="px-1"
                                                        elevation="0"
                                                        small
                                                        :color="textColor"
                                                        @click.native.stop="handleApplyFontColor(textColor);textColorDisplayMenu = false"
                                                        title="Detected color">
                                                            <v-icon
                                                                size="20"
                                                                class="outlined rounded-pill"
                                                                style="background-color: white; outline: 1px solid black"
                                                                color="black">
                                                                mdi-border-color
                                                            </v-icon>
                                                    </uv-button>
                                                </div>
                                                <div class="justify-end">
                                                    <uv-button
                                                    small
                                                    title="Close Menu"
                                                    color="grey darken-1" 
                                                    plain 
                                                    @click.native.stop="
                                                        textColorDisplayMenu = false;
                                                        editor.commands.focus()">
                                                    Cancel
                                                </uv-button>
                                                <uv-button
                                                    small
                                                    title="Apply Font Color"
                                                    color="green darken-1" 
                                                    plain 
                                                    @click.native.stop="handleApplyFontColor(lastSelectedFontColor);textColorDisplayMenu = false">
                                                    Apply
                                                </uv-button>
                                                </div>
                                            </div>
                                        </v-menu>
                                    </v-item-group>
                                    <uv-button
                                        :disabled="!websocket.edit_status"
                                        @click.native.stop="
                                            editor
                                                .chain()
                                                .focus()
                                                .unsetMark('textStyle')
                                                .run()
                                        "
                                        icon
                                        title="Clear format"
                                    >
                                        <v-icon size="20" color="black">mdi-format-clear</v-icon>
                                    </uv-button>
                                    <uv-button icon
                                    :disabled="!websocket.edit_status || showPageSideMenu"
                                    :btn-classes="{'is-active': showPageSideMenu}"
                                    @click.native.stop="openMenu" 
                                    title="Open Xbrl Menu">
                                        <v-icon size="20" color="black">mdi-menu-open</v-icon>
                                    </uv-button>
                                </div>

                                <div class="d-flex flex-wrap align-center mt-2">
                                    <template v-for="(item, idx) in editorMenu.tableMenu">
                                        <uv-button
                                            :key="`item_${idx}`"
                                            icon
                                            :height="item.height || 32"
                                            :width="item.width || 32"
                                            @click.native.stop="item.command(item)"
                                            :title="item.title"
                                            :disabled="!websocket.edit_status || !(!item.showCondition || item.showCondition())"
                                            :btn-classes="{
                                                'mx-1': true,
                                                'is-active': item.active && item.active(),
                                            }"
                                            :id="item.id"
                                        >
                                            <v-icon size="20" :color="item.color || 'black'">{{ item.icon }}</v-icon>
                                        </uv-button>
                                        <uv-grid-menu
                                            :disabled="!websocket.edit_status"
                                            v-if="item.gridMenu"
                                            offset-x
                                            :rows="item.gridMenu.rows"
                                            :cols="item.gridMenu.cols"
                                            :activator="'#' + item.id"
                                            :key="`grid_${idx}`"
                                            @close="item.gridMenu.close"
                                        ></uv-grid-menu>
                                        <v-divider
                                            class="mx-2"
                                            vertical
                                            v-if="item.divider && item.divider()"
                                            :key="`divider_${idx}`"
                                        ></v-divider>
                                    </template>
                                    <v-item-group class="elevation-2 mx-1 v-btn-toggle v-btn-toggle--dense">
                                        <v-btn
                                            :disabled="!websocket.edit_status || !editor.isActive('table')"
                                            @click.native.stop="handleApplyCellBorderColor(borderColor)"
                                            title="Apply border color"
                                        >
                                            <v-icon
                                                size="20"
                                                color="black"
                                                :style="{
                                                    borderBottom: `5px solid ${borderColor}`,
                                                    marginBottom: '-5px',
                                                }"
                                                >mdi-select-color</v-icon
                                            ></v-btn
                                        >
                                        <v-menu :close-on-content-click="false" :value="borderColorDisplayMenu" :disabled="!websocket.edit_status || !editor.isActive('table')">
                                            <template #activator="{ attrs, on }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    title="Select border color"
                                                    class="px-0"
                                                    width="1.5em"
                                                    min-width="1.5em"
                                                    @click.native.stop="borderColorDisplayMenu = true"
                                                >
                                                    <v-icon size="20" color="black">mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-color-picker
                                                dot-size="25"
                                                show-swatches
                                                class="rounded-0"
                                                :swatches="colorSwatches"
                                                swatches-max-height="200"
                                                v-model="borderColor"
                                                :disabled="!websocket.edit_status || !editor.isActive('table')"
                                                @dblclick.native.stop="handleApplyCellBorderColor(borderColor), borderColorDisplayMenu = false"
                                                @keyup.native.enter="handleApplyCellBorderColor(borderColor), borderColorDisplayMenu = false">
                                            </v-color-picker>
                                            <v-spacer></v-spacer>
                                            <div class="d-flex justify-end" style="background-color: white">
                                                <uv-button
                                                    title="Close Menu"
                                                    color="grey darken-1" 
                                                    small
                                                    plain 
                                                    @click.native.stop="
                                                        borderColorDisplayMenu = false; 
                                                        editor.commands.focus()">
                                                    Cancel
                                                </uv-button>
                                                <uv-button
                                                    title="Apply Border Color"
                                                    color="green darken-1" 
                                                    small
                                                    plain  
                                                    @click.native.stop="handleApplyCellBorderColor(borderColor), borderColorDisplayMenu = false">
                                                    Apply
                                                </uv-button>
                                            </div>
                                        </v-menu>
                                    </v-item-group>

                                    <v-item-group class="elevation-2 mx-1 v-btn-toggle v-btn-toggle--dense">
                                        <v-btn
                                            :disabled="!websocket.edit_status || !editor.isActive('table')"
                                            @click.native.stop="handleApplyCellBackroundColor(fillColor)"
                                            title="Apply fill color"
                                        >
                                            <v-icon
                                                size="20"
                                                color="black"
                                                :style="{
                                                    borderBottom: `5px solid ${fillColor}`,
                                                    marginBottom: '-5px',
                                                }"
                                                >mdi-format-color-fill</v-icon
                                            ></v-btn
                                        >
                                        <v-menu :close-on-content-click="false" :value="fillColorDisplayMenu" :disabled="!websocket.edit_status || !editor.isActive('table')">
                                            <template #activator="{ attrs, on }">
                                                <v-btn
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    title="Select fill color"
                                                    class="px-0"
                                                    width="1.5em"
                                                    min-width="1.5em"
                                                    @click.native.stop="fillColorDisplayMenu = true"
                                                >
                                                    <v-icon size="20" color="black">mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-color-picker
                                                dot-size="25"
                                                show-swatches
                                                class="rounded-0"
                                                :swatches="colorSwatches"
                                                swatches-max-height="200"
                                                v-model="fillColor"
                                                @dblclick.native.stop="handleApplyCellBackroundColor(fillColor), fillColorDisplayMenu = false"
                                                @keyup.native.enter="handleApplyCellBackroundColor(fillColor), fillColorDisplayMenu = false">
                                            </v-color-picker>
                                            <v-spacer></v-spacer>
                                            <div class="d-flex justify-end" style="background-color: white">
                                                <uv-button
                                                    title="Close Menu" 
                                                    color="grey darken-1" 
                                                    small
                                                    plain 
                                                    @click.native.stop="
                                                        fillColorDisplayMenu = false;
                                                        editor.commands.focus()">
                                                    Cancel
                                                </uv-button>
                                                <uv-button
                                                    title="Apply Background Color"
                                                    color="green darken-1" 
                                                    small
                                                    plain 
                                                    @click.native.stop="handleApplyCellBackroundColor(fillColor), fillColorDisplayMenu = false">
                                                    Apply
                                                </uv-button>
                                            </div>
                                        </v-menu>
                                    </v-item-group>
                                    <div>
                                        <v-select
                                            :disabled="!websocket.edit_status || !editor.isActive('table')"
                                            v-model="borderWidth"
                                            :items="borderWidthSelectItems"
                                            dense
                                            label="Border width"
                                            solo
                                            hide-details
                                            @click.native.stop
                                            @change="onBorderWidthChange"
                                        >
                                        </v-select>
                                    </div>
                                </div>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div class="d-flex flex-wrap align-center pb-4">
                                <div class="d-flex flex-wrap align-center">
                                    <template v-for="(item, idx) in editorMenu.secondaryMenu">
                                        <uv-button
                                            :disabled="!websocket.edit_status"
                                            :key="`item_${idx}`"
                                            v-if="!item.showCondition || item.showCondition()"
                                            icon
                                            :height="item.height || 32"
                                            :width="item.width || 32"
                                            @click="item.command(item)"
                                            :loading="item.busy"
                                            :title="item.title"
                                            :btn-classes="{
                                                'mx-1': true,
                                                'is-active': item.active && item.active(),
                                            }"
                                        >
                                            <v-icon size="20" :color="item.color || 'black'">{{ item.icon }}</v-icon>
                                        </uv-button>
                                        <v-divider
                                            class="mx-2"
                                            vertical
                                            v-if="item.divider && item.divider()"
                                            :key="`divider_${idx}`"
                                        ></v-divider>
                                    </template>

                                    <v-tooltip top>
                                        <template #activator="{ on, attrs }">
                                            <div v-bind="attrs" v-on="on">
                                                <v-file-input
                                                    :disabled="!websocket.edit_status"
                                                    class="image-file-input"
                                                    hide-details
                                                    hide-input
                                                    dense
                                                    @change="onImageLoad"
                                                    accept="image/*"
                                                >
                                                </v-file-input>
                                            </div>
                                        </template>
                                        <span>Insert image</span>
                                    </v-tooltip>
                                </div>
                            </div>

                            <div class="d-flex flex-wrap align-center">
                                <div>
                                    <v-text-field
                                        style="width: 130px"
                                        hide-details
                                        dense
                                        outlined
                                        :value="currentPage"
                                        @change="goToPage"
                                        label="Go to page"
                                        :suffix="`/${numberOfPages}`"
                                    >
                                    </v-text-field>
                                </div>
                                <v-menu :close-on-content-click="false" content-class="symbol-menu-content" :disabled="!websocket.edit_status">
                                    <template #activator="{ on, attrs}">
                                        <div class="ml-1">
                                            <uv-button v-on="on" v-bind="attrs" icon title="Insert symbol" :disabled="!websocket.edit_status"><span class="text-h5 secondary--text">&Omega;</span></uv-button>
                                        </div>
                                    </template>
                                    <v-sheet max-width="300" max-height="400px" class="d-flex flex-column">
                                        <v-text-field
                                            v-model="symbolSearch"
                                            clearable
                                            color="info"
                                            placeholder="Search"
                                            class="px-1">
                                            <template #prepend-inner>
                                                <v-icon size="28" color="info">mdi-magnify</v-icon>
                                            </template>
                                        </v-text-field>
                                        <v-tabs-items v-model="activeSymbolTab" style="overflow-y: auto" class="flex-grow-1">
                                            <v-tab-item v-for="(key, idx) in Object.keys(symbolEnums)" :key="idx">
                                                <v-container>
                                                    <v-row align="center" justify="space-around">
                                                        <v-col cols="auto" v-for="(item, index) in symbolEnums[key]" :key="`${key}-${index}`">
                                                            <uv-button icon color="black" 
                                                                @click="() => handleSymbolInsert(item.symbol)"
                                                                :title="item.name">
                                                                <span class="text-h5">{{item.symbol}}</span>
                                                            </uv-button>
                                                        </v-col>
                                                    </v-row>
                                                </v-container>
                                            </v-tab-item>
                                        </v-tabs-items>
                                        <v-tabs v-model="activeSymbolTab" class="flex-grow-0 symbol-tabs" show-arrows height="28px" grow>
                                            <v-tooltip bottom>
                                                <template #activator="{on, attrs}">
                                                    <v-tab v-on="on" v-bind="attrs">$</v-tab>
                                                </template>
                                                Currency
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template #activator="{on, attrs}">
                                                    <v-tab v-on="on" v-bind="attrs">&Omega;</v-tab>
                                                </template>
                                                Greek and Coptic
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template #activator="{on, attrs}">
                                                    <v-tab v-on="on" v-bind="attrs">&#8644;</v-tab>
                                                </template>
                                                Arrows
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template #activator="{on, attrs}">
                                                    <v-tab v-on="on" v-bind="attrs">&#9650;</v-tab>
                                                </template>
                                                Shapes
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template #activator="{on, attrs}">
                                                    <v-tab v-on="on" v-bind="attrs">&#10224;</v-tab>
                                                </template>
                                                Arrows supplemental A
                                            </v-tooltip>
                                            <v-tooltip bottom>
                                                <template #activator="{on, attrs}">
                                                    <v-tab v-on="on" v-bind="attrs">&#10496;</v-tab>
                                                </template>
                                                Arrows supplemental B
                                            </v-tooltip>
                                            <v-tabs-slider style="top: 0"></v-tabs-slider>
                                        </v-tabs>
                                    </v-sheet>
                                </v-menu>
                            </div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </div> 
            <div class="d-flex">
                <v-card-text v-if="hasContent" style="background-color: #555" :class="{pageEditor: showPageSideMenu }">
                    <editor-content :editor="editor" />
                </v-card-text>            
                <uv-page-side-menu v-if="showPageSideMenu" class="page-side-menu">
                    <template v-if="!showLoading">
                        <uv-taxonomies-menu class="flex-grow-1" v-if="!shouldShowAspectsMenu" @onApplyTag="handleApplyTag" @onRemoveTag="handleRemoveTag" @onGoToTag="handleGoToTag" :isReadOnly="!websocket.edit_status"></uv-taxonomies-menu>
                        <uv-column-aspects v-if="shouldShowAspectsMenu" @applyColumnAspects="handleColumnAspects" :isReadOnly="!websocket.edit_status"></uv-column-aspects>
                    </template> 
                    <div class="d-flex justify-center" v-if="showLoading">
                        <div class="text-center progressComponent">
                            <v-progress-circular
                            indeterminate
                            color="primary"> 
                            </v-progress-circular>
                        </div>
                    </div> 
                </uv-page-side-menu>               
            </div>
        </v-card>
        <v-system-bar class="status-bar d-flex justify-center">
            <div v-if="saveStatus === getRequestStatusEnum().SUCCESS">
                <v-icon color="success">mdi-check-bold</v-icon>
                <span class="success-text">Saved!</span>
            </div>
            <div v-else-if="saveStatus === getRequestStatusEnum().IDLE">
                <v-icon color="orange">mdi-exclamation-thick</v-icon>
                <span>Saved at: {{ this.lastSaveTime }}</span>
            </div>
            <div v-else-if="saveStatus === getRequestStatusEnum().LOADING">
                <v-progress-circular size="20" color="blue" class="mr-2"></v-progress-circular>
                <span class="success-text">Saving...</span>
            </div>
        </v-system-bar>

        <uv-confirm-dialog ref="confirmDialog">
            <span class="font-weight-bold">{{confirmationDialogText}}</span>
        </uv-confirm-dialog>
    </div>
</template>
<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import TableHeader from "@tiptap/extension-table-header";
import Focus from "@tiptap/extension-focus";
import TextAlign from "@tiptap/extension-text-align";
import FontFamily from "@tiptap/extension-font-family";
import TipTapTextStyle from "@tiptap/extension-text-style";
import {
    PageNode,
    CustomParagraph,
    CustomHeading,
    CustomTableCell,
    CustomTableRow,
    CustomTable,
    CustomImage,
    DocumentNode,
    PageHeader,
    PageFooter,
    XbrlBlockTagNode,
} from "@/editor/nodes";

import {
    CustomUnderline,
    CustomText
} from "@/editor/marks"

import {
    TextStyle,
    GeneralStyle,
    CustomColor
} from "@/editor/extensions"

import { editorMenu } from "./editormixins";
import editorcommonmixins from "./editorcommonmixins";
import { editorWebsocket } from "./wsMixin";
import fontSelectItems from "./fontSelectItems";
import editorConfig from "./editorConfig";
import colorSwatches from "./colorSwatches";
import { HeaderFooterEditor } from "../../components/editor";
import { symbolEnums } from "@/editor/utils";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
// import { CellSelection } from "prosemirror-tables";
import { findParentNodeClosestToPos, getMarksBetween } from "@tiptap/core";
import { debounce } from '@/plugins/utils';
import { NodeSelection } from 'prosemirror-state';
import { ReplaceStep } from 'prosemirror-transform';

export default {
    name: "HtmlReportEditor",
    mixins: [editorMenu, editorWebsocket, editorcommonmixins],
    components: {
        EditorContent,
        HeaderFooterEditor,
    },
    created() {
        this.reportAbortController = new AbortController;
        
        this.$apiHttp({ url: `/report/${this.$route.params.id}`,signal: this.reportAbortController.signal }).then((response) => {
            if (response.data) {
                this.setReportBaseInfo(response.data);
                this.isSimpleReport = !response.data.xbrl;
                if (response.data.xbrl_tags && response.data.xbrl_tags !== "") this.setReportTags(JSON.parse(response.data.xbrl_tags));
                this.loadContent()
                .finally(() => {
                    this.$vuetify.goTo(0, { container: "#main-container"});
                });
                if (!this.isSimpleReport){
                    this.getTaxonomies()
                }
            }
        });

        this.$store.commit("setPageMenu", {});

        this.editor.on("update", ({ editor, transaction }) => {
            if (!transaction.meta.updatingPages){
                this.updatePageIds(editor);
            }
            if(this.contentLoading === false && !transaction.meta.updatingPages) {
                let cond1 = transaction.steps.length === 1 && transaction.steps[0] instanceof ReplaceStep;
                let cond2 = cond1 && transaction.steps[0].slice?.content?.content.length === 1;
                let cond3 = cond2 && transaction.steps[0].slice?.content?.content[0].type?.name === 'image';
                if(!cond3){
                    this.editorHasChanges = true;
                }
            }
        });
        this.editor.on("selectionUpdate", ({transaction}) => {
            if (!this.contentLoading && !transaction.meta.newXbrlTableAdded && !transaction.meta.newXbrlRowAdded){
                this.selectionUpdateTimeoutId = debounce(this.selectionUpdateTimeoutId, 500, () => {
                    this.selection = transaction.selection;
                    this.tagsList = [];
                    let updatingSelected = transaction.getMeta("updatingSelected");
                    if (!updatingSelected) {
                        this.resolveEmptySelectionHighlight(transaction);       
                    }
                }, () => true)
            }
        });
        
    },
    beforeRouteLeave (to, from, next) {
        let dialog = this.$refs.confirmDialog;
        if(this.editorHasChanges === true) {
            dialog.open().then((confirmation) => {
                if (confirmation){
                    next();
                }else{
                    next(false);
                }
            });
        } else {
            next();
        }
    },
    destroyed() {
        if(this.contentLoading === true) {
            this.reportAbortController.abort(); 
        }
        if(this.taxonomies.loading === true) {
            this.taxonomiesAbortController.abort();
        }
    },
    data() {
        return {
            editor: new Editor({
                extensions: [
                    DocumentNode,
                    StarterKit.configure({
                        paragraph: false,
                        document: false,
                        heading: false,
                        bulletList: {
                            keepAttributes: true,
                            keepMarks: true
                        },
                        orderedList:{
                            keepAttributes: true,
                            keepMarks: true
                        }
                    }),
                    CustomUnderline,
                    CustomParagraph,
                    CustomHeading.configure({
                        levels: [1, 2, 3],
                    }),
                    Focus.configure({
                        className: "has-focus",
                        mode: "all",
                    }),
                    TextAlign.configure({
                        types: editorConfig?.TextAlign.types
                    }),
                    PageNode,
                    PageHeader,
                    PageFooter,
                    GeneralStyle.configure({
                        types: editorConfig?.GeneralStyle.types
                    }),
                    TipTapTextStyle,
                    TextStyle.configure({
                        types: editorConfig?.TextStyle.types
                    }),
                    FontFamily.configure({
                        types: editorConfig?.FontFamily.types
                    }),
                    CustomColor.configure({
                        types: editorConfig?.Color.types
                    }),
                    CustomImage.configure({
                        inline: true,
                    }),
                    CustomTableCell,
                    CustomTable.configure({
                        resizable: true,
                        allowTableNodeSelection: true,
                        cellMinWidth: 25,
                    }),
                    TableHeader,
                    CustomText,
                    CustomTableRow,
                    XbrlBlockTagNode
                ],
                editorProps: {
                    attributes: {
                        id: "my-editor",
                    },
                },
                editable: true,
                autofocus: true
            }),
            taxonomy: {},
            hasContent: false,
            contentLoading: false,
            fontSelectItems: fontSelectItems,
            editorConfig: editorConfig,
            numberOfPages: 0,
            defaultFooterContent: null,
            defaultHeaderContent: null,
            defaultFooterHeight: 20,
            defaultHeaderHeight: 20,
            activeSymbolTab: 0,
            symbolSearch: "",
            confirmationDialogText: "Leaving without saving!",
            tagsList: [],
            editorHasChanges: false,
            extendedBubbleMenu: false,
            selectionUpdateTimeoutId: null,
            isSimpleReport: true,
        };
    },
    computed: {
        ...mapState("xbrlData", {
            taxonomies: state => state.taxonomies,
            showPageSideMenu: state => state.displaySideMenu,
            selectionAttributes: state => state.selectionAttributes,
            reportInfo: state => state.reportInfo,
            reportTags: state => state.reportTags
        }),
        ...mapGetters("xbrlData", {
            getConceptData: "getConceptDataByXbrlId",
            getTagContinuations: "getTagContinuations",
        }),
        colorSwatches: function() {
            return [...colorSwatches];
        },
        selection: {
            get() {
                return this.$store.state.xbrlData.editorSelection;
            },
            set(value) {
                this.setEditorSelection(value);
            }
        },
        symbolEnums: function() {
            if (this.symbolSearch){
                let searchReg = new RegExp(this.symbolSearch, 'i');
                let filteredEnums = {};
                let keys = Object.keys(symbolEnums);
                for (let key of keys){
                    let filteredArray = symbolEnums[key].filter(symbol => symbol.name.match(searchReg));
                    filteredEnums[key] = filteredArray;
                }
                return filteredEnums;
            }else{
                return symbolEnums;
            }
        },
        shouldShowAspectsMenu: function() {
            return !this.isSimpleReport && (this.selection instanceof NodeSelection) && (this.selection?.node?.type?.name === "table");
        },
        showLoading: function() {
            return this.taxonomies?.loading;
        },
    },
    methods: {
        ...mapActions("xbrlData", {
            addTag: 'addTag',
            editTag: 'editTag',
            addNewTag: 'addNewTag',
            addNewRowTag: 'addNewRowTag',
            openSideMenu: 'openSideMenu',
            removeTagById: 'removeTagById',
            editTagByMarkId: 'editTagByMarkId',
        }),
        ...mapMutations("xbrlData", [
            "setReportTags",
            "setColumnAspectByTagIdAndIndex",
            "setEditorSelection",
            "setRowTagByIdAndTableId",
            "setSelectionAttributes",
            "removeRowTagByIdAndTableId",
            "setReportBaseInfo",
            "setTagById"
        ]),
        onFontChange(value) {
            this.editor
                .chain()
                .focus()
                .setFontFamily(value)
                .run();
        },
        onFontSizeChange(value) {
            let fontSize = new String(value);
            this.editor
                .chain()
                .focus()
                .setMark("textStyle", { fontSize })
                .run();
        },
        updatePageIds(editor) {
            let { doc } = editor.state;
            if (this.numberOfPages !== doc.childCount) {
                this.numberOfPages = doc.childCount;
                let pageNodePositions = [];
                doc.nodesBetween(0, doc.content.size, (_, pos) => {
                    pageNodePositions.push(pos);
                    return false; // returning false will not let nodesBetween method to go deeper inside the nodes, so it will stay at page level
                });
                for (let idx = 0; idx < pageNodePositions.length; idx++) {
                    let nodeSize = doc.child(idx).nodeSize;
                    editor
                        .chain()
                        .setMeta("updatingPages", true)
                        .setTextSelection(pageNodePositions[idx] + 1, nodeSize)
                        .updateAttributes("pageNode", {
                            "page-id": idx,
                        })
                        .run();
                }
            }
        },
        onFooterContentChange(value) {
            this.defaultFooterContent = value.editorObject.content;
            this.defaultFooterHeight = value.height;
        },
        onHeaderContentChange(value) {
            this.defaultHeaderContent = value.editorObject.content;
            this.defaultHeaderHeight = value.height;
        },
        handleSymbolInsert(symbol){
            let styleAttrs = this.editor.getAttributes("textStyle");
            if (styleAttrs) {
                this.editor.chain().focus().insertContent({type: 'text', text: symbol, marks: [{type: "textStyle", attrs: {...styleAttrs}}]}).run();
            }else{
                this.editor.chain().focus().insertContent({type: 'text', text: symbol}).run();
            }
        },
        openMenu() {
            if(!this.taxonomies?.pre && !this.taxonomies?.loading) {
                this.getTaxonomies()
            }
            this.openSideMenu();
            this.editor.commands.focus();
        },
        async handleColumnAspects(column) {
            let xbrlId = this.selection.node.attrs.xbrlId;
            if(xbrlId === null || xbrlId === undefined) { 
                // create new entry in the xbrlTags object
                let newId = await this.addNewTag({
                    tag: {
                        type: 'table',
                        columns: {},
                        rows: {},
                        tableDefaults: {}
                }, stringForHash: this.reportInfo.entity_identifier});
                xbrlId = newId;
                let tablePosition = this.selection.from;
                this.editor.chain().focus().setMeta("newXbrlTableAdded", true).updateAttributes('table', {xbrlId : xbrlId}, ).run();
                this.setColumnAspectByTagIdAndIndex({tagId: xbrlId, index: column.columnIndex, aspect: column});
                this.editor.commands.setNodeSelection(tablePosition)
            }else{
                this.setColumnAspectByTagIdAndIndex({tagId: xbrlId, index: column.columnIndex, aspect: column})
            }
        },
        async handleApplyTag(conceptId, tagAspects, editMode, selectedMark, selectedBlock) {
            if (this.editor.isActive("tableRow")){
                let rowAttrs = this.editor.getAttributes("tableRow");
                let tableAttrs = this.editor.getAttributes("table");
                let xbrlId = rowAttrs.xbrlId;
                let tableId = tableAttrs.xbrlId;
                if (!xbrlId){
                    let {tagId, tableId: tabId} = await this.addNewRowTag({tag: this.getConceptData(conceptId), stringForHash: this.reportInfo.entity_identifier, tableId,attrs: tagAspects});

                    if (!tableId){
                        this.editor.chain().focus().setMeta("newXbrlTableAdded", true).updateAttributes('table', {xbrlId : tabId}, ).run();
                    }
                    xbrlId = tagId;
                    tableId = tabId;
                    this.editor.chain().focus().setMeta("newXbrlRowAdded", true).updateAttributes('tableRow', {xbrlId}).run();
                    this.setSelectionAttributes({...this.selectionAttributes, row: tagId, table: tableId});
                }else{
                    this.setRowTagByIdAndTableId({id: xbrlId, tag: this.getConceptData(conceptId), tableId, attrs: tagAspects});
                    this.setSelectionAttributes({...this.selectionAttributes, row: xbrlId, table: tableId});
                    this.editor.chain().focus().updateAttributes('tableRow', {xbrlId}).run();
                }
            }else{
                if(!editMode) {
                    if(tagAspects.type != "block") {
                        if (this.editor.commands.isSelectionInOneNode()){
                            let newId = await this.addNewTag({tag: this.getConceptData(conceptId), stringForHash: this.reportInfo.entity_identifier, attrs: tagAspects});
                            this.selectionAttributes.marks.push(newId);
                            this.editor.chain().focus().setMark('customText', {xbrlId: newId, selected: true}).run();
                        }else{
                            this.$store.commit("setGlobalMessage", {
                                severity: "warning",
                                message: "Cannot add / remove inline tag to more than one paragraph. Use block tags."
                            });
                        }
                    } else {
                        let id = await this.addNewTag({tag: this.getConceptData(conceptId), stringForHash: this.reportInfo.entity_identifier, attrs: tagAspects});
                        this.editor.chain().focus().toggleWrap("xbrlBlockTagNode",{xbrlId: id}).run();
                        this.selectTextBlockNode();
                    }
                } else {
                    if (selectedMark) {
                        this.editTagByMarkId({id: selectedMark,tag: this.getConceptData(conceptId), attrs: tagAspects});
                    }else{
                        this.setTagById({id: selectedBlock, tag: this.getConceptData(conceptId), attrs: tagAspects});
                    }
                }
            }
        },
        handleRemoveTag(tagId) {
            let oldFrom = this.selection.from;
            let oldTo = this.selection.to;
            if(this.selectionAttributes.table) {
                this.editor.commands.updateAttributes('tableRow', {xbrlId: null});
                this.removeRowTagByIdAndTableId({tagId: this.selectionAttributes.row, tableId: this.selectionAttributes.table});
                this.selectionAttributes.row = null;
                this.selectionAttributes.table = null;
            } 
            if(this.selectionAttributes.marks && this.selectionAttributes.marks.length > 0) {
                let continuations = this.getTagContinuations(tagId);
                if (this.editor.chain().focus()
                    .removeClosestMarkTag(() => {
                        this.$store.commit("setGlobalMessage", {
                            severity: "warning",
                            message: "Cannot add / remove inline tag to more than one paragraph. Use block tags."
                        });
                    }, tagId) 
                    .run()) {
                        this.removeTagById({tagId});
                        for (let contId of continuations.blocks){
                            if (this.editor.commands.deleteBlockTagNodeById(contId)) {
                                this.removeTagById({tagId: contId});
                            }
                        }
                        for (let contId of continuations.marks){
                            if (this.editor.commands.removeMarkTagById(contId)){
                                this.removeTagById({tagId: contId});
                            }
                        }
                        let removedIndex = this.selectionAttributes.marks.indexOf(tagId);
                        this.selectionAttributes.marks.splice(removedIndex,1);
                    }
            }
            if(this.selectionAttributes.textBlock) {
                let continuations = this.getTagContinuations(tagId);
                if (this.editor.chain().focus().deleteBlockTagNode().run()){
                    this.removeTagById({tagId});
                    for (let contId of continuations.blocks){
                        if (this.editor.commands.deleteBlockTagNodeById(contId)) {
                            this.removeTagById({tagId: contId});
                        }
                    }
                    for (let contId of continuations.marks){
                        if (this.editor.commands.removeMarkTagById(contId)){
                            this.removeTagById({tagId: contId});
                        }
                    }
                    this.selectionAttributes.textBlock = null;
                }
            }         
            this.editor.commands.setTextSelection({from: oldFrom, to: oldTo});
        },
        handleApplyCellBackroundColor(color) {
            this.editor
            .chain()
            .focus()
            .setCellAttribute('backgroundColor', color)
            .run();  
        },
        handleApplyCellBorderColor(color) {
            this.editor
                .chain()
                .focus()
                .setCellAttribute('borderColor', color)
                .run(); 
        },
        onBorderWidthChange(value) {
            this.editor
                .chain()
                .focus()
                .setCellAttribute("borderWidth", value)
                .run();
        },
        handleApplyFontColor(color) {
            this.editor.chain().focus().setColor(color).run();
            this.textColor = color 
        },
        selectTextBlockNode() {
            let nodes = this.selection.$head.path
            for(let [index,value] of nodes.entries()) {
                if(value.type?.name === "xbrlBlockTagNode") {
                    this.editor.chain().focus().setNodeSelection(nodes[index - 1]).run();
                }
            }
        },
        setSelectedMarkStyle(attrs) {
            this.editor.chain()
                .focus()
                .setMeta("updatingSelected", true)
                .updateMarkTag(attrs)
                .run();
        },
        getLastSelectedMark(transaction){
            let marksWithPos = getMarksBetween(0, transaction.doc.content.size,transaction.doc);
            for (let markWithPos of marksWithPos){
                if (markWithPos.mark.attrs?.xbrlId !== null && markWithPos.mark.attrs?.xbrlId !== undefined 
                    && markWithPos.mark.attrs?.selected === true) return markWithPos;
            }
            return null;
        },
        unselectLastMarkTag(transaction) {
            let lastSelectedMark = this.getLastSelectedMark(transaction);
            if (lastSelectedMark){
                this.editor.chain()
                    .setMeta("updatingSelected", true)
                    .updateMarkTag({...lastSelectedMark.mark.attrs, selected: false}, { from: lastSelectedMark.from, to: lastSelectedMark.to})
                    .run();
            }
        },
        handleGoToTag(id) {
            this.editor.commands.goToTag(id, () => {
                this.$vuetify.goTo(`[data-xbrl-id="${id}"]`, {
                    container: "#main-container",
                    offset: 300
                })
            });
        },
        resolveEmptySelectionHighlight(tr) {
            this.unselectLastMarkTag(tr);
            let oldFrom = tr.selection.from;
            let oldTo = tr.selection.to;
            let wasNodeSelection = false;
            // let wasCellSelection = false;
            // if (tr.selection instanceof CellSelection){
            //     wasCellSelection = true;
            // }else
            if (tr.selection instanceof NodeSelection) {
                wasNodeSelection = true;
            }
            if (oldFrom === oldTo){
                this.setSelectionAttributes({...this.selectionAttributes, marks: [], row: null, table: null, textBlock: null});
                let mark = this.findMarkTagOnCursor(tr);
                if(mark && this.editor.commands.isSelectionInOneNode()) {
                    this.tagsList.push(mark.mark?.attrs.xbrlId);
                    this.setSelectedMarkStyle({...mark.mark.attrs, selected: true});
                    this.setSelectionAttributes({...this.selectionAttributes, marks: this.tagsList, row: null, table: null});
                } else {
                    let parentNode = findParentNodeClosestToPos(tr.selection.$from, (node) => {
                        if(node.type.name === "tableRow" && this.nodeHasXbrlId(node)) {
                            return true;
                        }
                        if(node.type.name === "xbrlBlockTagNode" && this.nodeHasXbrlId(node)){
                            return true;
                        }
                    });
                    if (parentNode){
                        if (parentNode.node?.type?.name === "tableRow"){
                            let tmpObj = this.findTableXbrlId(parentNode.node.attrs.xbrlId); 
                            this.setSelectionAttributes({...this.selectionAttributes, marks: [], row: tmpObj.row, table: tmpObj.table});
                        }else{
                            this.setSelectionAttributes({...this.selectionAttributes, marks: [], row: null, table: null, textBlock: parentNode.node.attrs.xbrlId});
                        }
                    }
                }
                this.editor.chain().setMeta("updatingSelected", true).setTextSelection({from: oldFrom, to: oldTo}).run();
            }
            // if (wasCellSelection){
            //     true;
            // }else 
            if (wasNodeSelection){
                this.editor.chain().setMeta("updatingSelected", true).setNodeSelection(oldFrom).run();
            }else{
                // this.editor.chain().setMeta("updatingSelected", true).setTextSelection({from: oldFrom, to: oldTo}).focus().run();
            }
        },
        nodeHasXbrlId(node) {
            if(node.attrs.xbrlId != null && node.attrs.xbrlId != undefined) return true;
        },
        findTableXbrlId(xId) {
            for(let [index,tag] of Object.entries(this.reportTags)) {
                if(tag.type === "table") {
                    let rows = this.reportTags[index].rows;
                    for(let id in rows) {
                        if(id === xId) {
                            return {table: index, row: id};
                        }
                    }
                }
            }
        },
        findMarkTagOnCursor(transaction){
            let markTags = this.getMarkTagsOfNode(transaction);
            let markWithPos = null;
            for (let markTagGroup of markTags.reverse()){
                for (let markTag of markTagGroup){
                    if (markTag.from < transaction.selection.from && markTag.to > transaction.selection.to) {
                        markWithPos = markTag;
                        break;
                    }
                }
                if (markWithPos) break;
            };
            return markWithPos;
        },
        getMarkTagsOfNode(transaction){
            let parentNode = findParentNodeClosestToPos(transaction.selection.$from, (node) => {
                return ["paragraph", "heading"].includes(node?.type?.name);
            });
            let start = transaction.selection.$from.start(parentNode?.node?.depth);
            let end = transaction.selection.$from.end(parentNode?.node?.depth);
            let foundMarks = getMarksBetween(start, end, transaction.doc).filter((markWithPos) => {
                return markWithPos.mark?.attrs?.xbrlId
            });
            let markTags = [];
            for (let markWithPos of foundMarks){
                let parentGroup = markTags.findLast((markTagGroup) => {
                    for (let markTag of markTagGroup){
                        if (markTag.from !== markWithPos.from && markTag.to !== markWithPos.to && markTag.mark.attrs.xbrlId === markWithPos.mark.attrs.xbrlId){
                            return true;
                        }
                    }
                    return false;
                });
                if (parentGroup){
                    for (let markTag of parentGroup){
                        if (markTag.from <= markWithPos.from && markTag.to === markWithPos.from && markTag.mark.attrs.xbrlId === markWithPos.mark.attrs.xbrlId){
                            markTag.to = markWithPos.to;
                        }
                    }
                }else{
                    let parentGroup = markTags.findLast((markTagGroup) => {
                        for (let markTag of markTagGroup){
                            if (markTag.from <= markWithPos.from || markTag.to >= markWithPos.to && markTag.mark.attrs.xbrlId !== markWithPos.mark.attrs.xbrlId){
                                return true;
                            }
                        }
                        return false;
                    })
                    if (parentGroup){
                        let parentDepth = markTags.indexOf(parentGroup);
                        if (parentDepth + 1 < markTags.length ){
                            markTags[parentDepth + 1].push(markWithPos);
                        }else{
                            markTags.push([markWithPos]);
                        }
                    }else{
                        markTags.push([markWithPos]);
                    }
                }
            }
            return markTags;
        }
    },
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lobster&display=swap");
</style>
<style lang="scss">
.symbol-menu-content {
    overflow-y: hidden;
    .symbol-tabs {
        .v-tab {
            min-width: unset;
        }
    }
}
#editor-page {
    .mark-tag[data-xbrl-id] {
        &:hover {
            outline: 2px dashed blue;
        }
    }
    .mark-tag[data-selected]{
        outline: 2px solid blue;
        &:hover {
            outline: 2px solid blue;
        }
    }
    .block-tag[data-xbrl-id]:hover {
        outline: 2px dashed blue;
    }
    .block-tag[data-xbrl-id].has-focus{
        outline: 3px solid blue;
    }
    $background-color: #555;
    #my-editor {
        white-space: pre-wrap;
        background-color: $background-color;
        font-size: 14px;
        color: white;
        &.ProseMirror-focused {
            outline-style: none;
        }
        &.resize-cursor {
            cursor: ew-resize;
        }

        &.ProseMirror-gapcursor {
            display: none;
            pointer-events: none;
            position: absolute;
        }
        &.ProseMirror-gapcursor:after {
            content: "";
            display: block;
            position: absolute;
            top: -2px;
            width: 20px;
            border-top: 1px solid black;
            background-color: white;
            animation: ProseMirror-cursor-blink 1.1s steps(2, start) infinite;
        }
        @keyframes ProseMirror-cursor-blink {
            to {
                visibility: hidden;
            }
        }

        .editor-paragraph {
            margin: 0;
        }

        .double-underline {
            text-decoration: underline;
            text-decoration-style: double;
        }

        .total .figure::first-line,
        .double-underline .figure::first-line {
            text-decoration: none;
            border-bottom: 3px double;
        }

        .total .label {
            font-weight: bold;
        }
        .nota {
            padding-left: unset !important;
            text-align: center;
        }
        .underline {
            text-decoration: underline;
        }
        .underline .figure::first-line {
            text-decoration: none;
            border-bottom: 1px solid;
        }

        .indented .label {
            text-indent: 1em;
        }
        .page {
            width: 220mm;
            height: 317mm;
            background-color: white;
            color: black;
            margin-top: 0.5cm;
            margin-bottom: 1cm;
            margin-left: auto;
            margin-right: auto;
            position: relative;
            font-size: 12pt;
            line-height: normal;
            padding: 1cm 1.8cm 1cm 1.8cm;
            overflow-y: visible;
            box-sizing: border-box;
            page-break-after: always;
            &.landscape {
                height: 220mm;
                width: 297mm;
            }
            table {
                border-collapse: collapse;
                max-width: 100%;
                // table-layout: auto;
                // border-collapse: collapse;
                // margin-left: auto;
                // margin-right: auto;
                // margin-bottom: 1em;
                tr {
                    &:hover {
                        background-color: blanchedalmond !important;
                    }
                    &.emptyrow {
                        height: 0.75em;
                    }
                }

                tbody {
                    // td {
                    //     position: relative;
                    //     vertical-align: bottom;
                    //     background-clip: padding-box;
                    // }
                    .column-resize-handle {
                        position: absolute;
                        right: -2px;
                        top: 0;
                        bottom: -2px;
                        width: 4px;
                        background-color: #adf;
                        pointer-events: none;
                    }
                }

                .selectedCell {
                    outline: 3px solid blue;
                    z-index: 1;
                }
            }

            p {
                margin: 0;
                padding: 0;
                min-height: 1em;
            }

            ol,
            ul {
                margin: 0;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                margin: 0;
            }
        }
    }
    .is-active {
        background-color: rgba(175, 120, 0, 0.1);
    }
    .v-select__selections input {
        width: 0;
    }
    .image-file-input {
        .v-icon {
            color: black;
        }
    }
    .editor-menu {
        position: sticky;
        top: 0;
        z-index: 2;
    }

    .status-bar {
        position: fixed;
        bottom: 1rem;
        right: 25px;
        width: 200px;
        border-radius: unset;
        border-top-left-radius: 1em;
        border-top-right-radius: 1em;
        background-color: white;
    }

    .pageEditor {
        max-width: 60vw;
        overflow: auto;
    }

    .page-side-menu {
        flex-grow: 0.4;
        position: sticky;
        top: 116px;
        z-index: 2;
        overflow: hidden;
    }
    .progressComponent {
        padding-top: 50%;
    }
}
</style>
